import { ApolloError, gql, useMutation } from '@apollo/client';
import { BulkModifyInventoryInput, Inventory, QueryInventoryArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INVENTORY } from './useGetInventory';

export const BULK_UPDATE_INVENTORY = gql`
  mutation BulkUpdateInventory($input: BulkModifyInventoryInput!) {
    bulkModifyInventory(input: $input) {
      inventories {
        id
      }
    }
  }
`;

export interface BulkInventoryMutationResponse {
  responseData?: Inventory[];
  responseMessage: string;
}

interface BulkUpdateInventoryResult {
  bulkUpdateInventory: (input: BulkModifyInventoryInput, columnName: string, queryVariables: QueryInventoryArgs) => Promise<BulkInventoryMutationResponse>;
  bulkUpdateInventoryLoading: boolean;
  bulkUpdateInventoryError?: ApolloError;
}

export const useBulkUpdateInventory = (): BulkUpdateInventoryResult => {
  const [updateMultipleInventory, { loading, error }] = useMutation(BULK_UPDATE_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('BulkUpdateInventory').error("Error updating multiple inventory -->", error.message);
    }
  });

  const bulkUpdateInventory = async (input: BulkModifyInventoryInput, columnName: string, refetchVariables: QueryInventoryArgs): Promise<BulkInventoryMutationResponse> => {
    const response = await updateMultipleInventory({ 
      variables: { input },
      refetchQueries: [{
        query: GET_INVENTORY,
        variables: refetchVariables,
      }],
    });

    const responseData = response.data?.bulkModifyInventory?.inventories;

    if (responseData) {
      logger('BulkUpdateInventory').info(`${columnName} successfully updated on all selected record(s)`, response.data);
      return {
        responseData,
        responseMessage: `${columnName} successfully updated on all selected record(s)!`
      };
    } else {
      return {
        responseMessage: `Failed to update ${columnName}!`,
      };
    }
  };

  return { 
    bulkUpdateInventory, 
    bulkUpdateInventoryLoading: loading, 
    bulkUpdateInventoryError: error,
  };
};
