import { ApolloError, gql, useMutation } from '@apollo/client';
import { logger } from 'src/helpers/logger';
import { DeleteMutationResponse } from '../interfaces';

export const BULK_DELETE_INVENTORY_FROM_LOCATION = gql`
  mutation BulkDeleteInventoryFromLocation($inventoryLocationIds: [String!]!) {
    bulkRemoveInventoryFromLocation(inventoryLocationIds: $inventoryLocationIds) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface BulkDeleteInventoryFromLocationResult {
  bulkRemoveInventoryFromLocation: (inventoryLocationIds: string[]) => Promise<DeleteMutationResponse>;
  bulkRemoveInventoryFromLocationLoading: boolean;
}

export const useBulkDeleteInventoryFromLocation = (): BulkDeleteInventoryFromLocationResult => {
  const [bulkDeleteInventoryFromLocation, { loading }] = useMutation(BULK_DELETE_INVENTORY_FROM_LOCATION, {
    onError: (error: ApolloError) => {
      logger('BulkDeleteInventoryFromLocation').error('Error deleting inventory/inventories from location -->', error.message);
    }
  });

  const bulkRemoveInventoryFromLocation = async (inventoryLocationIds: string[]): Promise<DeleteMutationResponse> => {
    const response = await bulkDeleteInventoryFromLocation({ 
      variables: { inventoryLocationIds },
    });
    
    const responseData = response.data?.bulkRemoveInventoryFromLocation?.deleteResult;
    if (responseData?.success) {
      logger('BulkDeleteInventoryFromLocation').info(`Successfully removed inventory from location`, response.data);
      return {
        responseData,
        responseMessage: `Successfully removed inventory from location!`
      };
    } else {
      return {
        responseMessage: `Failed to remove inventory from location!`,
      };
    }
  };

  return { 
    bulkRemoveInventoryFromLocation, 
    bulkRemoveInventoryFromLocationLoading: loading, 
  };
};
