import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isNil, size, last, omit, isString, isArray, isEmpty } from 'lodash';
import { DeleteTwoTone } from '@mui/icons-material';
import { Icon, Portal, Alert, IconButton, Snackbar, AlertColor } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import { handleCharLimitWarning } from 'src/utils';
import IconAddBarCode from '../../../assets/icon-bc-add.svg';
import IconRemoveBarCode from '../../../assets/icon-bc-remove.svg';
import IconPrintBarCode from '../../../assets/icon-bc-print.svg';
import CartIcon from 'src/assets/icon-cart.svg';
import { CHAR_LIMIT, FormType, GridType, SEVERITY } from '../../../consts';
import InventorySummaryForm from './component/InventorySummaryForm';
import { validateForm } from './utils';
import SpareForEquipmentTab from './component/SpareForEquipmentTab';
import OrderHistoryTab from './component/OrderHistoryTab';
import BarCode, { BarcodeActionType } from './component/BarCodes/BarCode';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import BarcodePopup from 'src/components/UI/PDF/reports/BarcodePrintButton/BarcodePopup';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, Barcode, DeleteResult, Inventory, InventoryInput, InventoryType, Order, OrderItem, QueryInventoryArgs, RecordType, UserError } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';
import CompaniesDropDown from 'src/components/Dropdowns/CompaniesDropdown';
import AddInventoryToPurchaseOrderDialog from './component/AddInventoryToPurchaseOrderDialog';
import { useUpsertInventory } from 'src/hooks/inventory/useUpsertInventory';
import { useDeleteInventory } from 'src/hooks/inventory/useDeleteInventory';

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface InventoryDetailFormProps extends Partial<InjectedDrawerProps> {
  initialValue: Inventory;
  recordType: RecordType;
  isCreate: boolean;
  onSave: (responseData: Inventory, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: DeleteResult, responseMessage: string) => void;
  onCancel: () => void;
  refreshGridView?: () => void;
  refetchQueryVariables?: QueryInventoryArgs;
  inventoryType: InventoryType;
  moduleReadOnly: boolean;
  viewType?: GridType;
  type?: FormType;
}

interface InventoryDetailFormRef {
  onSaveClick: () => void;
  onDeleteClick: () => void;
}

const InventoryDetailForm = forwardRef<InventoryDetailFormRef, InventoryDetailFormProps>(({
  initialValue,
  viewType,
  recordType,
  isCreate,
  onSave,
  onDelete,
  onCancel,
  refetchQueryVariables,
  inventoryType,
  refreshGridView,
  moduleReadOnly = false,
  type,
  setFormIsDirty
}: InventoryDetailFormProps, ref) => {
  const classes = useStyles();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      categoryId: initialValue.categoryId,
      productName: initialValue.productName || '',
      manufacturer: initialValue.manufacturer,
      modelNumber: initialValue.modelNumber || '',
      partNumber: initialValue.partNumber || '',
      department: initialValue?.department || null,
      sms: initialValue.sms || false,
      rx: initialValue.rx || false,
      consumable: initialValue.consumable || false,
      bonded: initialValue.bonded || false,
      productDescription: initialValue.productDescription || '',
      reOrderLevel: initialValue.reOrderLevel,
      qty: initialValue.qty || null,
      orderQty: initialValue.orderQty || null,
      factor: initialValue.factor || null,
      reOrderAmt: initialValue.reOrderAmt,
      id: initialValue.id,
      color: initialValue?.color,
      size: initialValue?.size,
      country: initialValue?.country,
      region: initialValue?.region
    },
  });

  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const formInitialValues = useRef<any>({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [removePopupVisible, setRemovePopupVisible] = useState<boolean>(false);
  const [printBarcodeDialogVisible, setPrintBarcodeDialogVisible] = useState<boolean>(false);
  const [addInventoryToPOVisible, setAddInventoryToPOVisible] = useState<boolean>(false);
  const [isDeletingWarningPopup, setIsDeletingWarningPopup] = useState<boolean>(false);
  const { INVENTORY } = CHAR_LIMIT;
  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [equipmentCount, setEquipmentCount] = useState<number>(initialValue.equipmentCount || 0);
  const [ordersCount, setOrdersCount] = useState<number>(initialValue.ordersCount || 0);
  const [documentsCount, setDocumentsCount] = useState<number>(initialValue.documentsCount || 0);
  const [photosCount, setPhotosCount] = useState<number>(initialValue.photosCount || 0);
  const [commentsCount, setCommentsCount] = useState<number>(initialValue.commentsCount || 0);
  const { upsertInventory } = useUpsertInventory();
  const { removeInventory } = useDeleteInventory();

  useImperativeHandle(ref, () => ({
    onSaveClick: () => {
      handleSubmit(handleSave)();
    },
    onDeleteClick: () => {
      handleDelete();
    }
  }));

  const setRecordReadOnlyPermission = async ()=> {
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.department){
      setRecordReadOnly(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal && inventoryType === InventoryType.GeneralInventory){
      setRecordReadOnlyPermission()
    }
  },[settingsPersonal]);
  
  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    setInitialValues();

    return () => {
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    if (name === 'categoryId') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.categoryId === updatedValue) {
        shouldDirty = false;
      }
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = async (data: any) => {

    if (!validateForm(data, setSnackbar)) return;

    const {
      productName,
      categoryId,
      manufacturer,
      modelNumber,
      partNumber,
      department,
      sms,
      rx,
      consumable,
      bonded,
      productDescription,
      reOrderLevel,
      qty,
      orderQty,
      factor,
      reOrderAmt,
      color,
      size,
      country,
      region
    } = data;

    const qtyValue = isString(qty) ? qty : qty?.member || null;
    const orderQtyValue = isString(orderQty) ? orderQty : orderQty?.member || null // or orderQty?.fldMember

    const document: InventoryInput = {
      id: initialValue.id || null, // Set primary key, so we will be able to upsert.
      productName,
      categoryId: (isArray(categoryId) ? last(categoryId) : categoryId) || null,
      manufacturer: typeof manufacturer === 'object' ? manufacturer?.name : manufacturer || null,
      modelNumber,
      partNumber: isEmpty(partNumber) ? null : partNumber.toUpperCase(),
      department: department && typeof department === 'object' ? department.member : department || null,
      color: color && typeof color === 'object' ? color.member : color || null,
      size: size && typeof size === 'object' ? size.member : size || null,
      country: country && typeof country === 'object' ? country.member : country || null,
      region: region && typeof region === 'object' ? region.member : region || null,
      productDescription,
      sms,
      rx,
      consumable,
      bonded,
      reOrderLevel: parseInt(reOrderLevel) || null,
      qty: qtyValue,
      orderQty: orderQtyValue,
      factor: (qtyValue === orderQtyValue ? 1.0 : parseFloat(factor)) || 1.0,
      reOrderAmt: parseInt(reOrderAmt) || null,
      inventoryType: initialValue.inventoryType
    };

    const { responseData, responseMessage } = await upsertInventory(document, isCreate, refetchQueryVariables);

    if (responseData) {
      onSave(responseData as Inventory, responseMessage, isCreate);
      reset(getValues());
    }
  };

  const handleDelete = () => {
    setIsDeletingWarningPopup(true)
  };

  const handleDeleteOk = async () => {
    const { responseData, responseMessage } = await removeInventory(initialValue.id, refetchQueryVariables, viewType);
    responseData?.success && onDelete && onDelete(responseData, responseMessage);
    setIsDeletingWarningPopup(false);
  };

  const handleDeleteCancel = () => {
    setIsDeletingWarningPopup(false);
  }

  const handleCancelUndo = () => {
    if (isCreate) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleAddBarCodePress = () => {
    setAddPopupVisible(true);
  };

  const handleDeleteBarCodePress = () => {
    setRemovePopupVisible(true);
  };

  const onPrintBarcodeClick = () => {
    setPrintBarcodeDialogVisible(true)
  };

  const handleAddInvToOrderItems = () => {
    setAddInventoryToPOVisible(true)
  };

  const handleDialogCancel = () => {
    setAddPopupVisible(false);
    setRemovePopupVisible(false);
    setPrintBarcodeDialogVisible(false);
    handleClose();
  };

  const handleBarCodeSave = async (responseMessage: string, responseData?: Barcode | DeleteResult, responseDataError?: UserError) => {
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  }

  const handleBarCodeRemove = async (responseMessage: string, responseData?: Barcode | DeleteResult, responseDataError?: UserError) => {
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleAddOrderItemSave = (responseMessage: string, responseData?: OrderItem, responseDataError?: UserError) => {
    if (responseData) {
      setOrdersCount(ordersCount + 1)
    }
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleAddOrderItemCancel = () => {
    setAddInventoryToPOVisible(false);
  };

  const actions = [
    {
      icon: (
        <Icon>
          <img src={IconPrintBarCode} alt="" />
        </Icon>
      ),
      name: 'Print',
      onclick: onPrintBarcodeClick,
    },
    {
      icon: (
        <Icon>
          <img src={IconAddBarCode} alt="" />
        </Icon>
      ),
      name: 'Add',
      onclick: handleAddBarCodePress,
    },
    {
      icon: (
        <Icon>
          <img src={IconRemoveBarCode} alt="" />
        </Icon>
      ),
      name: 'Remove',
      onclick: handleDeleteBarCodePress,
    },
    {
      icon: (
        <Icon>
          <img src={CartIcon} alt="" />
        </Icon>
      ),
      name: 'Add to PO',
      onclick: handleAddInvToOrderItems,
    },
  ];

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;
  
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const formClass = type === FormType.DIALOG
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  const manufacturerLabel = inventoryType === InventoryType.Beverages ? 'Maker/Bottler' : 'Manufacturer';
  const partNumberLabel = inventoryType === InventoryType.Beverages ? 'Varietal' : 'Part Number';
  
  return (
    <form
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          {(moduleReadOnly || recordReadOnly) && (
            <RecordEditWarningCard />
          )}
          <div className="mui-textfield-header mb-2">
            <Input
              inputProps={{
                size: 'medium',
                label: 'Product Name',
                variant: 'standard',
              }}
              rules={{ required: true, maxLength: INVENTORY.productName }}
              warning={(value) => handleCharLimitWarning(value, INVENTORY.productName)}
              control={control}
              name="productName"
            />
          </div>

          <div className="mt-3">
            <CompaniesDropDown
              control={control}
              label={manufacturerLabel}
              name="manufacturer"
              onChange={onChange}
              variant="standard"
            />
          </div>

          {(inventoryType === InventoryType.GeneralInventory || inventoryType === InventoryType.Beverages) &&
            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: partNumberLabel,
                  variant: 'standard',
                }}
                rules={{ maxLength: INVENTORY.partNumber }}
                warning={(value) => handleCharLimitWarning(value, INVENTORY.partNumber)}
                defaultValue={initialValue.partNumber}
                control={control}
                name="partNumber"
              />
            </div>
          }
          
          <div className="mt-6 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <InventorySummaryForm
                      inventory={initialValue}
                      recordType={recordType}
                      inventoryType={inventoryType}
                      onChange={onChange}
                      control={control}
                      isCreate={isCreate}
                      readOnly={moduleReadOnly || recordReadOnly}
                      refreshGridView={refreshGridView}
                    />
                  ),
                },
                {
                  label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.id}
                      recordType={recordType}
                      recordTypeName={initialValue.__typename!}
                      attachmentType={AttachmentType.Document}
                      categoryId={initialValue.categoryId}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  label: `Photos (${photosCount})`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.id}
                      recordType={recordType}
                      recordTypeName={initialValue.__typename!}
                      attachmentType={AttachmentType.Photo}
                      categoryId={initialValue.categoryId}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  label: `Order History (${ordersCount})`,
                  disabled: isCreate,
                  component: (
                    <OrderHistoryTab
                      inventoryId={initialValue.id}
                    />
                  ),
                },
                ...(inventoryType === InventoryType.GeneralInventory
                  ? [
                      {
                        label: `Spare for Equipment (${equipmentCount})`,
                        disabled: isCreate,
                        component: (
                          <SpareForEquipmentTab
                            inventoryId={initialValue.id}
                            setEquipmentCount={setEquipmentCount}
                            disableEdit={moduleReadOnly || recordReadOnly}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  label: `Comments (${commentsCount})`,
                  disabled: isCreate,
                  component: (
                    <Comments
                      recordId={initialValue.id}
                      recordType={recordType}
                      recordTypeName={initialValue.__typename!}
                      setCommentsCount={setCommentsCount}
                      readOnly={moduleReadOnly || recordReadOnly}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      {type !== FormType.DIALOG && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? (isCreate ? 'Create' : 'Save') : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={(moduleReadOnly || recordReadOnly) && isEditing}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
        >
          {!moduleReadOnly && !recordReadOnly && !isNil(initialValue.id) && (
            <>
              <Box sx={{ position: 'relative', height: 70 }}>
                <Backdrop open={open} />
                <SpeedDial
                  ariaLabel="Speed dial with actions"
                  sx={{ position: 'absolute', bottom: 12, right: 12 }}
                  FabProps={{ size: 'small' }}
                  icon={(
                    <SpeedDialIcon
                      sx={{ fontSize: 'small' }}
                      openIcon={<CloseIcon />}
                    />
                  )}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      classes={classes}
                      onClick={action.onclick}
                    />
                  ))}
                </SpeedDial>
              </Box>

              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete item"
              >
                <DeleteTwoTone />
              </IconButton>
            </>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeletingWarningPopup}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <BarcodePopup
        inventory={initialValue}
        onCancel={handleDialogCancel}
        visible={printBarcodeDialogVisible}
      />

      <BarCode
        visible={addPopupVisible}
        onCancel={handleDialogCancel}
        onSave={handleBarCodeSave}
        inventory={initialValue}
        type={BarcodeActionType.Add}
      />

      <BarCode
        visible={removePopupVisible}
        onCancel={handleDialogCancel}
        onSave={handleBarCodeRemove}
        inventory={initialValue}
        type={BarcodeActionType.Remove}
      />

      <AddInventoryToPurchaseOrderDialog
        visible={addInventoryToPOVisible}
        inventory={initialValue}
        onCancel={handleAddOrderItemCancel}
        onSave={handleAddOrderItemSave}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </form>
  );
});

export default InventoryDetailForm;
