import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryInventoryArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { InventoryDeleteMutationResponse } from './useDeleteInventory';
import { GET_INVENTORY } from './useGetInventory';

export const BULK_DELETE_INVENTORY = gql`
  mutation BulkDeleteInventory($inventoryIds: [String!]!) {
    bulkDeleteInventory(inventoryIds: $inventoryIds) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface BulkDeleteInventoryResult {
  bulkRemoveInventory: (inventoryIds: string[], queryVariables: QueryInventoryArgs) => Promise<InventoryDeleteMutationResponse>;
  bulkRemoveInventoryLoading: boolean;
}

export const useBulkDeleteInventory = (): BulkDeleteInventoryResult => {
  const [bulkDeleteInventory, { loading }] = useMutation(BULK_DELETE_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('BulkDeleteInventory').error('Error deleting inventory/inventories -->', error.message);
    }
  });

  const bulkRemoveInventory = async (inventoryIds: string[], refetchVariables: QueryInventoryArgs): Promise<InventoryDeleteMutationResponse> => {
    const response = await bulkDeleteInventory({ 
      variables: { inventoryIds },
      refetchQueries: [
        {
          query: GET_INVENTORY,
          variables: refetchVariables,
        }
      ],
    });
    
    const responseData = response.data?.bulkDeleteInventory?.deleteResult;
    if (responseData?.success) {
      logger('BulkDeleteInventory').info(`${inventoryIds.length > 1 ? 'Multiple inventory' : 'Inventory'} deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `${inventoryIds.length > 1 ? 'Multiple inventory' : 'Inventory'} removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove ${inventoryIds.length > 1 ? 'Multiple inventory' : 'Inventory'}!`,
      };
    }
  };

  return { 
    bulkRemoveInventory, 
    bulkRemoveInventoryLoading: loading, 
  };
};
