import { gql, useQuery } from '@apollo/client';
import { DashboardTile } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_INFO_TILE = gql`
  query GetInfoTile {
    dashboardTile {
      contents
      title
    }
  }
`;

interface DashboardTileResult {
  data: DashboardTile;
  loading: boolean;
}

export const useGetInfoTile = (): DashboardTileResult => {
  const { data, loading, error } = useQuery(GET_INFO_TILE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  if (error) {
    logger('GetInfoTile').error('Error dashboard tile data -->', error.message);
  }

  return {
    data: data?.dashboardTile || {title: '', contents: ''},
    loading,
  };
};
