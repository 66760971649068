import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { InventoryCollectionSegment, InventoryFilterInput, InventorySortInput,  } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_INVENTORY = gql`
  query GetLazyInventory(
    $filterInput: InventoryFilterInput, 
    $order: [InventorySortInput!]
  ) {
    inventory(filterInput: $filterInput, order: $order) {
      items {
        id
        productName
        manufacturer
        partNumber
        department
        consumable
        bonded
        sms
        reOrderLevel
        totalAmount
      }
    }
  }
`

interface LazyInventoryData {
  inventory: InventoryCollectionSegment;
}

interface LazyInventoryResult {
  getLazyInventory: LazyQueryExecFunction<LazyInventoryData, OperationVariables>;
}

export const useGetLazyInventory = (filterInput: InventoryFilterInput, order: InventorySortInput[]): LazyInventoryResult => {

  const [ getLazyInventory, { error }] = useLazyQuery(GET_LAZY_INVENTORY, {
    fetchPolicy: "network-only",
    variables: { filterInput, order },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyInventory').error('Error fetching inventory -->', error.message);
  }

  return {
    getLazyInventory
  };
};