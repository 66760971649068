//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import { isNil } from 'lodash';
import { CellProps } from '@inovua/reactdatagrid-enterprise/types';
import {SchedMaintEQDocument} from "../../rxdb/collections/SchedMaintEQ/schema";
import LicensedReactDataGrid from "../../components/UI/LicensedReactDataGrid";
import {getDatabase} from "../../rxdb";
import {Subscription} from "rxjs";
import { Button, useMediaQuery } from '@mui/material';
import moment from "moment";
import NumberFilter from '@inovua/reactdatagrid-enterprise/NumberFilter';
import {
  flattenResults,
  InterfaceScheduleWithSchedMaint,
  mergeEquipmentAndSchedMainResults, onRender, populateSchedMaintEQData,
  SchedMainEqWithSchedMainWithPendingTaskCount
} from "../../pages/EquipmentSchedules/utils";
import GridRowActions from "../../components/UI/LicensedReactDataGrid/components/GridRowActions";
import GridRowScheduleIcon from "../../components/Schedules/GridRowScheduleIcon";
import EquipmentSchedulesFormDialog from "../../components/dataentry/EquipmentSchedulesDE/EquipmentSchedulesFormDialog";
import {uuid} from "uuidv4";
import { preProcessSelectionRecurrence } from 'src/utils';
import { normalizeDateTime } from 'src/helpers';
import { logger } from 'src/helpers/logger';
import AddIcon from '@mui/icons-material/Add';

interface InjectedProps {
  selector: { [key: string]: any };
  EqKey?: string;
  recordReadOnly: boolean;
}

const Schedules = ({ selector, EqKey, recordReadOnly = false }: InjectedProps) => {
  const [data, setData] = useState<InterfaceScheduleWithSchedMaint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const scheduleSubscription = useRef<Subscription>();
  const [schedule, setSchedule] = useState<InterfaceScheduleWithSchedMaint>();

  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)')

  const initialize = async () => {
    const db = await getDatabase();

    // Find schedules based on selector that passed outside
    scheduleSubscription.current = db
      .tblschedmainteq
      .find({ selector })
      .$
      .subscribe(async schedules => { // Interested in real-time updates while tab open
        const scheduleKeys = schedules.map(s => s.SchedKey );

        const schedMaint = await db
          .tblschedmaint
          .find({ selector: { PKey: { $in: scheduleKeys }, deletedAt: {$eq:null}}})
          .exec();

        const workissues = await db
          .workissues
          .find({
            selector: {
              $and: [
                { fldSchedMaintKey: {$in: scheduleKeys }},
                { Completed: { $eq: false }},
                { deletedAt: {$eq:null}},
              ],
            },
          })
          .exec();

        setData(mergeEquipmentAndSchedMainResults(schedules, [], schedMaint, workissues) as SchedMainEqWithSchedMainWithPendingTaskCount[]);
        setLoading(false);
      }, () => setLoading(false));
  };

  useEffect(() => {
    initialize();
    return () => {
      scheduleSubscription.current?.unsubscribe();
    }
  }, []);

  const handleEditClick = async (data: SchedMaintEQDocument) => {
    const populated = await populateSchedMaintEQData(data) as InterfaceScheduleWithSchedMaint;
    setSchedule(preProcessSelectionRecurrence(populated));
  };
  const handleDeleteClick = (data: any) => {
    return data.original.remove();
  };

  const handleSuspendAll = async () => {
    const db = await getDatabase();

    const schedMaints = await db.tblschedmainteq.find({ selector });

    await schedMaints.update({
      $set: {
        fldDeferred: true,
        fldDeferredDate: normalizeDateTime(new Date()),
      }
    });
  };
  const handleReactivateAll = async () => {
    const db = await getDatabase();

    await db
      .tblschedmainteq
      .find({ selector })
      .update({
        $set: {
          fldDeferred: false,
          fldDeferredDate: null
        }
      });
  };
  const handleAddPMClick = async () => {
    const db = await getDatabase();

    const equipment = await db
        .equipment
        .findOne({ selector: { EqKey } })
        .exec();

    const schedMaint = db.tblschedmaint.newDocument({
      PKey: uuid(),
      Department: equipment?.Department,
      fldSchedType: 'PM',
      fldListType: 'Scheduled Maintenance'
    });

    const maintEq = db.tblschedmainteq.newDocument({
      SchedKey: schedMaint.PKey,
      PKey: uuid(),
      EqKey,
    });

    const result = flattenResults(maintEq, equipment || undefined, schedMaint) as InterfaceScheduleWithSchedMaint

    setSchedule(result);
  };
  const handleSave = async (sched: SchedMaintEQDocument, isCreation: boolean, e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    setSchedule(undefined);
  };

  const renderActions = (props: CellProps) =>
    <GridRowActions
      {...props}
      onEditClick={handleEditClick}
      onDeleteClick={handleDeleteClick}
    />;

  const columns = [
    { 
      id: 'icons',
      header: 'Icons',
      render: GridRowScheduleIcon, 
      onRender: onRender,
      flex: 0.5,
      visible: !(isTablet || isMobile),
    },
    {
      name: 'fldSubject',
      header: 'Subject',
      groupBy: false,
      flex: 1,
    },
    {
      name: 'fldHoursTrigger',
      header: 'Hours Next Due',
      groupBy: false,
      flex: 1,
      visible: !(isTablet || isMobile),
      filterEditor: NumberFilter,
      render: ({data}:any) =>{
        return data.fldHoursTrigger >= 0 ? data.fldHoursTrigger : null
      }
    },
    {
      name: 'fldDateTrigger',
      header: 'Date Next Due',
      groupBy: false,
      flex: 1,
      visible: !isMobile,
      dateFormat: 'DD-MMM-YYYY',
      render: ({ value, cellProps: { dateFormat } }: { value: Date, cellProps: { dateFormat: string } }) => {
        if (!isNil(value)) {
          return moment(value).format(dateFormat);
        }
      },
    },
    {
      id: "actions",
      header: 'Actions',
      flex: 0.8,
      render: renderActions,
    },
  ];

  if(recordReadOnly) {
    columns.pop(); // Remove edit and delete buttons for users with no access
  }

  return (
    <div>
      <div className="flex justify-end mb-6">
        <Button startIcon={<AddIcon />} className="mr-3" variant="contained" onClick={handleAddPMClick} disabled={recordReadOnly}>Add PM</Button>
        <Button className="mr-3" variant="outlined" onClick={handleSuspendAll} disabled={recordReadOnly}>Suspend All</Button>
        <Button variant="outlined" onClick={handleReactivateAll} disabled={recordReadOnly}>Reactivate All</Button>
      </div>
      <div data-testid="data-grid" className="flex flex-col flex-grow eq-schedules">
        <LicensedReactDataGrid
          defaultLimit={20}
          livePagination
          rowHeight={40}
          loading={loading}
          idProperty="PKey"
          showHoverRows={false}
          columns={columns}
          dataSource={data}
        />
      </div>

      {schedule && (
        <EquipmentSchedulesFormDialog
          visible={!!schedule}
          onSave={handleSave}
          onCancel={() => setSchedule(undefined)}
          initialValue={schedule}
        />
      )}
    </div>
  )
};

export default Schedules;