import React, { useState, useEffect } from 'react';
import { Card, Typography, CardContent, CardHeader, Divider, Grid, Box, CircularProgress, Button, Portal, Alert, Snackbar, CardActionArea } from '@mui/material';
import { pick } from 'lodash';
import VesselEditDialog from 'src/components/dataentry/vesselDE/VesselEditDialog';
import { useAppState } from '../../../contexts/app-state';
import { TblShipsParticularsDocument } from '../../../api/queries/tblShipsParticulars/rxdb';
import { getDatabase } from '../../../rxdb';

const VesselTile = () => {
  const { settingsPersonal } = useAppState();
  const [vessel, setVessel] = useState<TblShipsParticularsDocument | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [editButtonDisplay, setEditButtonDisplay] = useState<boolean>(false);

  const [eidtPopupVisible, setEditPopupVisible] = useState<boolean>(false);

  const [editInitialValue, setEditInitialValue] = useState<TblShipsParticularsDocument | null>(null);

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleEditDisplay = async () => {
    if (settingsPersonal.fldTritonSettings != null) {
      if (
        settingsPersonal.fldNoRights != true &&
        settingsPersonal?.fldTritonSettings === 2
      ) {
        setEditButtonDisplay(true);
      }
    }
  };

  useEffect(() => {
    if (settingsPersonal) {
      handleEditDisplay();
    }
  }, [settingsPersonal]);

  const transformData = (issue: TblShipsParticularsDocument) => ({
    ...pick(issue),
    original: issue,
  });

  const init = async () => {
    setLoading(true);

    try {
      const db = await getDatabase();
      db.tblshipsparticulars.find().$.subscribe(vessels => {
        const data = vessels.map(transformData);
        setVessel(data[0]?.original);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const EditButtonClick = () => {
    setEditInitialValue(vessel);
    setEditPopupVisible(true);
  };

  const EditCancel = () => {
    setEditInitialValue(null);
    setEditPopupVisible(false);
  };

  const EditSave = () => {
    setSnackbar({
      open: true,
      type: 'success',
      message: 'Vessel update success!',
    });
    init();
  };

  const CommonGridUI = (title: string, value: any) => (
    <>
      <Grid item xs={5}>
        <Typography sx={{ fontSize: 12 }}>{title}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
          sx={{ fontSize: 12, fontWeight: 700 }}
          className="flex justify-end"
        >
          {value}
        </Typography>
      </Grid>
    </>
  );
  const vesselSpecification = () => (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={3.5} columnSpacing={2}>
        {CommonGridUI('Vessel Name', vessel?.fldVesselName)}
        {CommonGridUI('LOA', vessel?.fldLOA)}
        {CommonGridUI('Gross Ton', vessel?.fldGrossTon)}
        {CommonGridUI('Type', vessel?.fldEngineType)}
        {CommonGridUI('Flag/Port', `${vessel?.fldFlag || ''} / ${vessel?.fldPortOfReg || ''}`)}
        {CommonGridUI('Official Number', vessel?.fldOfficialNum)}
        {CommonGridUI('IMO Number', vessel?.fldRegNum)}
        {CommonGridUI('MMSI Number', vessel?.fldMMSI)}
        {CommonGridUI('Call Sign', vessel?.fldCallSign)}
        {CommonGridUI('AAIC Number', vessel?.fldAAIC)}
      </Grid>
    </Box>
  );

  return (
    <>
      <Card
        elevation={3}
        sx={{ borderTop: 4, borderColor: '#0088FE',height: 425,  
        '@media (min-width: 1200px) and (max-width: 1400px)': {
          height: 450,
        }, }}
      >
        <CardContent sx={{ padding: 2 }}>
          <CardHeader
            sx={{ padding: 1 }}
            title={(
              <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                Vessel Specifications
              </Typography>
            )}
          />
          <Divider className="mb-3" />
          {loading ? (
            <div className="flex items-center justify-center w-full h-72">
              <CircularProgress />
            </div>
          ) : (
            vesselSpecification()
          )}
        </CardContent>
        <CardActionArea>
          {editButtonDisplay && (
            <div className="flex items-center justify-center mt-2">
              <Button size="small" onClick={EditButtonClick}>
                Edit Vessel Specifications
              </Button>
            </div>
          )}
        </CardActionArea>
      </Card>
      {eidtPopupVisible && (
        <VesselEditDialog
          visible={eidtPopupVisible}
          initialValue={editInitialValue}
          onCancel={EditCancel}
          onSave={EditSave}
        />
      )}
      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default VesselTile;
