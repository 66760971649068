import { ApolloError, gql, useMutation } from '@apollo/client';
import { InventoryLocation, InventoryLocationInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { last } from 'lodash';
import { InventoryLocationMutationResponse } from '../interfaces';

export const UPDATE_INVENTORY_LOCATION_AMOUNT = gql`
  mutation UpdateInventoryLocationAmount($input: InventoryLocationInput!) {
    upsertInventoryLocation(input: $input) {
      inventoryLocation {
        id
        amount
      }
    }
  }
`;

interface UpdateInventoryLocationAmountResult {
  updateInventoryLocationAmount: (inventoryLocation: InventoryLocation, increase: boolean) => Promise<InventoryLocationMutationResponse>;
  updateInventoryLocationAmountLoading: boolean;
  updateInventoryLocationAmountError?: ApolloError;
}

export const useUpdateInventoryLocationAmount = (): UpdateInventoryLocationAmountResult => {
  const [updateAmount, { loading, error }] = useMutation(UPDATE_INVENTORY_LOCATION_AMOUNT, {
    onError: (error: ApolloError) => {
      logger('UpdateInventoryLocationAmount').error("Error updating inventory location amount -->", error.message);
    }
  });

  const updateInventoryLocationAmount = async (inventoryLocation: InventoryLocation, increase: boolean): Promise<InventoryLocationMutationResponse> => {
    const updatedAmount = increase ? inventoryLocation.amount + 1 : inventoryLocation.amount - 1;
    const response = await updateAmount({ 
      variables: { 
        input: {
          id: inventoryLocation.id,
          locationId: Array.isArray(inventoryLocation.locationId) ? last(inventoryLocation.locationId) : inventoryLocation.locationId,
          inventoryId: inventoryLocation.inventory.id,
          amount: Number(updatedAmount),
        }
      },
    });

    const responseData = response.data?.upsertInventoryLocation?.inventoryLocation;

    if (responseData) {
      logger('UpdateInventoryLocationAmount').info(`Amount updated successfully`, response.data);
      return {
        responseData,
        responseMessage: `Amount updated successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to update amount!`,
      };
    }
  };

  return { 
    updateInventoryLocationAmount, 
    updateInventoryLocationAmountLoading: loading, 
    updateInventoryLocationAmountError: error,
  };
};
