import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { QueryLogEntriesArgs, LogEntryFilterInput, LogEntrySortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { LogEntriesResult } from './useGetLogEntriesWithCounts';

export const GET_LOG_ENTRIES_WITH_NO_COUNTS = gql`
  query GetLogEntriesWithNoCounts($skip: Int, $take: Int, $filterInput: LogEntryFilterInput!, $order: [LogEntrySortInput!]) {
    logEntriesWithNoCounts: logEntries(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        id
        logDate
        scheduleId
        equipmentId
        cost
        curr
        company
        time
        timeQty
        hours
        performedBy
        enteredBy
        tsachk
        department
        crewId
        restricted
        isWarranty
        locationId
        dateEntered
        isCheckList
        workList
        html
        categoryId
        sms
        checks
        year
        month
        category {
          category
          id
        }
        equipment {
          __typename
          id
          countHours
          hoursOffset
          hours
          uniqueName
          sms
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetLogEntriesWithNoCounts = (skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]): LogEntriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRIES_WITH_NO_COUNTS, {
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]) => {
    logger('GetLogEntriesWithNoCounts').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.logEntriesWithNoCounts) {
          const { items, pageInfo } = result.data.logEntriesWithNoCounts;
          logger('GetLogEntriesWithNoCounts').info('Log entries fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetLogEntriesWithNoCounts').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetLogEntriesWithNoCounts').error('Error re-fetching log entries -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetLogEntriesWithNoCounts').error('Error fetching log entries -->', error.message);
  }

  return {
    data: loading ? [] : data?.logEntriesWithNoCounts?.items || [],
    totalCount: data?.logEntriesWithNoCounts?.totalCount || 0,
    currentCount: data?.logEntriesWithNoCounts?.items?.length || 0,
    pageInfo: data?.logEntriesWithNoCounts?.pageInfo,
    loading,
    loadData,
  };
};
