import '../../home.scss'
import { useState, useEffect } from 'react';
import { Card, Typography, CardContent, CardHeader, Divider, CircularProgress, Button, Alert, Snackbar, AlertColor, CardActionArea } from '@mui/material';
import { useAppState } from '../../../../contexts/app-state';
import InfoEditDialog from './InfoEditDialog';
import { useGetInfoTile } from 'src/hooks/dashboard/useGetInfoTile';
import { DashboardTile, DashboardTileInput } from 'src/generated/dotnet.graphql';
import { useUpdateInfoTile } from 'src/hooks/dashboard/useUpdateInfoTile';
import { SEVERITY } from 'src/consts';
import RichTextEditor, { QUILL_FORMATS } from 'src/components/UI/Forms/RichTextEditor';
import ReactQuill from 'react-quill';

const quillStyles = {
  container: {
    border: 'none',   // Remove border
    padding: '0',     // Remove padding
    minHeight: '150px', // Set minimum height
  },
  editor: {
    backgroundColor: '#fff', // Set background color
    color: '#000',           // Set text color
    whiteSpace: 'pre-wrap',   // Ensure white spaces are respected
  },
};

const InfoTile = () => {
  const { settingsPersonal } = useAppState();
  const { data, loading } = useGetInfoTile();
  const { updateInfoTile, updateInfoTileLoading } = useUpdateInfoTile();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const [editButtonDisplay, setEditButtonDisplay] = useState<boolean>(false);
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [editInitialValue, setEditInitialValue] = useState<DashboardTile>(data);

  const handleEditDisplay = async () => {
    if (settingsPersonal.fldTritonSettings != null) {
      if (
        settingsPersonal.fldNoRights != true &&
        settingsPersonal?.fldTritonSettings === 2
      ) {
        setEditButtonDisplay(true);
      }
    }
  };

  useEffect(() => {
    if (settingsPersonal) {
      handleEditDisplay();
    }
  }, [settingsPersonal]);

  const handleEdit = () => {
    setEditInitialValue(data);
    setEditPopupVisible(true);
  };

  const handleEditCancel = () => {
    setEditPopupVisible(false);
  };

  const handleEditSave = async (data: DashboardTileInput) => {
    const { responseData, responseMessage } = await updateInfoTile(data);
    
    if(responseData) {
      setEditPopupVisible(false);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  return (
    <>
      <Card
        elevation={3}
        sx={{
          borderTop: 4,
          borderColor: '#0088FE',
          height: 425,
          display: 'flex',
          flexDirection: 'column', // Make the card content stack vertically
          '@media (min-width: 1200px) and (max-width: 1400px)': {
            height: 450,
          },
        }}
      >
        <CardHeader
          sx={{ padding: 1, marginLeft: 2, marginTop: 2 }}
          title={(
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
              {data.title}
            </Typography>
        )}
        />
        <Divider    
          sx={{
            width: '96%', // Adjust the width as needed (e.g., 90% of the card width)
            margin: '0 auto', // Center the divider horizontally
            marginBottom: 2, // Add space below the divider
          }}
        />
        <CardContent
          sx={{
            flex: 1, // Take up remaining vertical space
            overflow: 'auto', // Enable scrolling for overflowing content
            padding: 2,
          }}
          className='custom-scrollbar'
        >       
          {loading ? (
            <div className="flex items-center justify-center w-full h-72">
              <CircularProgress />
            </div>
          ) : (
            <ReactQuill
              theme="snow"
              readOnly={true}
              value={data.contents || ''}
              modules={{ toolbar: false }}
              className="html-text-display"
              formats={QUILL_FORMATS}
            />
          )}
        </CardContent>
        <CardActionArea>
          {editButtonDisplay && (
            <div className="flex items-center justify-center mt-2 mb-3">
              <Button size="small" onClick={handleEdit}>
                Edit Tile Contents
              </Button>
            </div>
          )}
        </CardActionArea>
      </Card>

      {editPopupVisible && (
        <InfoEditDialog
          visible={editPopupVisible}
          initialValue={editInitialValue}
          onCancel={handleEditCancel}
          onSave={handleEditSave}
        />
      )}
      
      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default InfoTile;


