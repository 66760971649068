import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import {InventoryCollectionSegment, InventoryLocation } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_INVENTORY_AT_LOCATION = gql`
  query GetLazyInventoryAtLocation($locationId: String!, $filterInput: InventoryFilterInput) {
    inventoryAtLocation(locationId: $locationId, filterInput: $filterInput) {
      amount
      id
      locationId
      locationPath
      serialNum
      amount
      max
      useBy
      isDefault
      dateEntered
      inventory {
        id
        productName
        photo
        manufacturer
        partNumber
        modelNumber
        categoryId
        department
        consumable
        bonded
        sms
        country
        region
        color
        size
        reOrderLevel
        orderQty
        totalAmount
        lowStock
        expiredAtLocation
        documentsCount
        photosCount
        commentsCount
        equipmentCount
        ordersCount
      }
    }
  }
`

interface InventoryLocationData {
  inventoryAtLocation: InventoryLocation[];
}

interface InventoryLocationResult {
  getLazyInventoryAtLocation: LazyQueryExecFunction<InventoryLocationData, OperationVariables>;
  loading: boolean;
}

export const useGetLazyInventoryAtLocation = (): InventoryLocationResult => {

  const [ getInventoryAtLocation, { error, loading }] = useLazyQuery(GET_LAZY_INVENTORY_AT_LOCATION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyInventoryAtLocation').error('Error fetching inventory at location -->', error.message);
  }

  return {
    getLazyInventoryAtLocation: getInventoryAtLocation,
    loading
  };
};