import { ApolloError, gql, useMutation } from '@apollo/client';
import { InventoryLocation, InventoryLocationInput, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INVENTORY_LOCATIONS } from './useGetInventoryLocation';
import { InventoryLocationMutationResponse } from '../interfaces';

export const UPDATE_INVENTORY_LOCATION = gql`
  mutation UpdateInventoryLocation($input: InventoryLocationInput!) {
    modifyInventoryLocation(input: $input) {
      inventoryLocation {
        id
        locationId
        serialNum
        amount
        max
        useBy
        isDefault
        locationPath
        dateEntered
      }
      errors {
        code
        message
      }
    }
  }
`;

interface UpdateInventoryLocationResult {
  updateInventoryLocation: (input: InventoryLocationInput) => Promise<InventoryLocationMutationResponse>;
  updateInventoryLocationLoading: boolean;
  updateInventoryLocationError?: ApolloError;
}

export const useUpdateInventoryLocation = (): UpdateInventoryLocationResult => {
  const [modifyInventoryLocation, { loading, error }] = useMutation(UPDATE_INVENTORY_LOCATION, {
    onError: (error: ApolloError) => {
      logger('UpdateInventoryLocation').error("Error updating inventory at location -->", error.message);
    }
  });

  const updateInventoryLocation = async (input: InventoryLocationInput): Promise<InventoryLocationMutationResponse> => {
    const response = await modifyInventoryLocation({ 
      variables: { input },
      // refetchQueries: [
      //   {
      //     query: GET_INVENTORY_LOCATIONS,
      //     variables: { inventoryId: input.inventoryId }
      //   },
    // ],
    });

    const responseData = response.data?.modifyInventoryLocation?.inventoryLocation;

    if (responseData) {
      logger('UpdateInventoryLocation').info(`Inventory at location updated successfully`, response.data);
      return {
        responseData,
        responseMessage: `Inventory at location updated successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to update inventory at location!`,
      };
    }
  };

  return { 
    updateInventoryLocation, 
    updateInventoryLocationLoading: loading, 
    updateInventoryLocationError: error,
  };
};
