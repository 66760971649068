import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box } from '@mui/material';
import Input from 'src/components/UI/Forms/Input';
import { CHAR_LIMIT, ErrorCode } from 'src/consts';
import { handleCharLimitWarning } from 'src/utils';
import { Barcode, DeleteResult, Inventory, UserError } from 'src/generated/dotnet.graphql';
import { useAddBarcodeToInventory } from 'src/hooks/inventory/useAddBarcodeToInventory';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useRemoveBarcodeToInventory } from 'src/hooks/inventory/useRemoveBarcodeFromInventory';

export enum BarcodeActionType {
  Add = 'Add',
  Remove = 'Remove',
}

interface InjectedProps {
  visible: boolean;
  onCancel?: () => void;
  onSave: (responseMessage: string, responseData?: Barcode | DeleteResult, responseDataError?: UserError) => void;
  inventory: Inventory;
  type: string;
}

const BarCode: FC<InjectedProps> = ({
  visible,
  onCancel,
  onSave,
  inventory,
  type,
}) => {
  const { control, handleSubmit, reset } = useForm<any>({ defaultValues: { barcodeNumber: null } });
  const { INVBARCODES } = CHAR_LIMIT;
  const [duplicateBarcodeMessage, setDuplicateBarcodeMessage] = useState<string>('');
  const [barcodeNumber, setBarcodeNumber] = useState<string>('');
  const [isDuplicateBarcodeWarning, setIsDuplicateBarcodeWarning] = useState(false);
  const [cannotAddDuplicateBarcodeWarning, setCannotAddDuplicateBarcodeWarning] = useState(false);
  const [isDeleteWarning, setIsDeleteWarning] = useState<boolean>(false);

  const { addBarcodeToInventory } = useAddBarcodeToInventory();
  const { removeBarcodeFromInventory } = useRemoveBarcodeToInventory();

  const handleCancel = () => {
    reset();
    onCancel && onCancel();
  };

  const handleOkDuplicate = async () => {
    const { responseData, responseDataError, responseMessage } = await addBarcodeToInventory(barcodeNumber, inventory.id, true);
    if (responseDataError?.code === ErrorCode.BarcodeExistsForInventory.code) {
      setDuplicateBarcodeMessage(ErrorCode.BarcodeExistsForInventory.message);
      setCannotAddDuplicateBarcodeWarning(true);
    } else {
      setIsDuplicateBarcodeWarning(false);
      handleCancel();
      onSave(responseMessage, responseData, responseDataError);
    }
  };

  const handleAddBarcode = async (barcodeNumber: string) => {
    const { responseData, responseDataError, responseMessage } = await addBarcodeToInventory(barcodeNumber, inventory.id);
    if (responseDataError?.code === ErrorCode.BarcodeExists.code) {
      setBarcodeNumber(barcodeNumber);
      setDuplicateBarcodeMessage(ErrorCode.BarcodeExists.message);
      setIsDuplicateBarcodeWarning(true);
      setCannotAddDuplicateBarcodeWarning(false);
    } else {
      handleCancel();
      onSave(responseMessage, responseData, responseDataError);
    }
  };

  const handleOkDelete = async () => {
    const { responseData, responseDataError, responseMessage } = await removeBarcodeFromInventory(barcodeNumber, inventory.id);
    setIsDeleteWarning(false);
    handleCancel();
    onSave(responseMessage, responseData, responseDataError);
  };

  const onSubmit = async (data: Barcode) => {
    if (type === BarcodeActionType.Add) {
      await handleAddBarcode(data.barcodeNumber);
    } else if (type === BarcodeActionType.Remove) {
      setBarcodeNumber(data.barcodeNumber);
      setIsDeleteWarning(true);
    }
  };

  const handleWarningCancel = () => {
    setIsDuplicateBarcodeWarning(false);
    setCannotAddDuplicateBarcodeWarning(false);
    setIsDeleteWarning(false);
    reset();
  };

  return (
    <form className="relative bg-white flex-grow">
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="sm"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{`${type} Bar Code`}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 4 }}>
          <div>
            <span>
              {`Enter the barcode you would like to ${type === BarcodeActionType.Add ? 'add to' : 'remove from'} "${inventory.productName}".`}
            </span>
            <div className="pt-5">
              <Input
                control={control}
                name="barcodeNumber"
                rules={{ 
                  required: true, 
                  maxLength: INVBARCODES.fldTritonBC,
                }}
                warning={(value) => handleCharLimitWarning(value, INVBARCODES.fldTritonBC)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
            <Button onClick={handleSubmit(onSubmit)} className="mr-3" variant="contained">
              Ok
            </Button>
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <WarningDialog
        visible={isDeleteWarning}
        title="Delete Warning"
        content="Are you sure you wish to remove barcode from inventory?"
        okText="Yes"
        color="error"
        onOk={handleOkDelete}
        onCancel={handleWarningCancel}
      />

      <WarningDialog
        visible={isDuplicateBarcodeWarning || cannotAddDuplicateBarcodeWarning}
        title="Duplicate Barcode Warning"
        content={duplicateBarcodeMessage}
        color="error"
        onOk={!cannotAddDuplicateBarcodeWarning ? handleOkDuplicate : undefined}
        onCancel={handleWarningCancel}
        okText={!cannotAddDuplicateBarcodeWarning ? "Yes" : undefined}
        cancelText={!cannotAddDuplicateBarcodeWarning ? "Cancel" : "Close"}
      />
    </form>
  );
};

export default BarCode;
