import { ApolloError, gql, useMutation } from '@apollo/client';
import { AddInventoryToPurchaseOrderInput, OrderItem, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET__INVENTORY_ORDER_HISTORY } from './useGetInventoryOrderHistory';
import { UpdateInventoryOrdersCountCache } from '../fragments.graphql';

export const ADD_INVENTORY_TO_PURCHASE_ORDER = gql`
  mutation AddInventoryToPurchaseOrder($input: AddInventoryToPurchaseOrderInput!) {
    addInventoryToPurchaseOrder(input: $input) {
      orderItem {
        id
        amount
        orderId
        inventoryId
        unit
        currency
        productDescription
        unitPrice
      }
      errors {
        code
        message
      }
    }
  }
`;

export interface AddInventoryToPurchaseOrderMutationResponse {
  responseData?: OrderItem;
  responseDataError?: UserError;
  responseMessage: string;
}

interface AddInventoryToPurchaseOrderResult {
  addInventoryToPurchaseOrder: (input: AddInventoryToPurchaseOrderInput) => Promise<AddInventoryToPurchaseOrderMutationResponse>;
  addInventoryToPurchaseOrderLoading: boolean;
}

export const useAddInventoryToPurchaseOrder = (inventoryId: string): AddInventoryToPurchaseOrderResult => {
  const [createInventoryToPurchaseOrder, { loading, error }] = useMutation(ADD_INVENTORY_TO_PURCHASE_ORDER, {
    onError: (error: ApolloError) => {
      logger('AddInventoryToPurchaseOrder').error(`Error adding inventory to purchase order -->`, error.message);
    },  
    update: (cache, { data }) => {
      if (data?.addInventoryToPurchaseOrder?.orderItem) {
      
        const recordCacheId = cache.identify({
          __typename: 'Inventory',
          id: inventoryId,
        });
              
        const existingRecord = cache.readFragment<any>({
          id: recordCacheId,
          fragment: UpdateInventoryOrdersCountCache,
        });

        if (existingRecord) {
          const updatedCount = { ordersCount: existingRecord.ordersCount + 1 };

          cache.writeFragment({
            id: recordCacheId,
            fragment: UpdateInventoryOrdersCountCache,
            data: updatedCount,
          });

        } else {
          logger('Cache-AddInventoryToPurchaseOrder').warning(`Inventory: ${inventoryId} not found in cache`);
        }
      } else {
        logger('Cache-AddInventoryToPurchaseOrder').warning(`Inventory: ${inventoryId} cache update failed --> No response from upsert mutation`);
      }
    }
  });

  const addInventoryToPurchaseOrder = async (input: AddInventoryToPurchaseOrderInput): Promise<AddInventoryToPurchaseOrderMutationResponse> => {
    const response = await createInventoryToPurchaseOrder({ 
      variables: { input },
      refetchQueries: [
        {
          query: GET__INVENTORY_ORDER_HISTORY,
          variables: {
            inventoryId: input.inventoryId
          },
        },
      ],
    });
    const responseData = response.data?.addInventoryToPurchaseOrder?.orderItem;
    const responseDataError = response.data?.addInventoryToPurchaseOrder?.errors;

    if (responseData) {
      logger('AddInventoryToPurchaseOrder').info('Inventory added to purchase order successfully', response.data);
      return {
        responseData,
        responseMessage: `Inventory added to purchase order successfully!`,
      };
    } else if (responseDataError) {
      logger('UpsertInventoryLocation').info('Add inventory to location duplicate', response.data);
      return {
        responseDataError: responseDataError[0],
        responseMessage: responseDataError[0].message,
      }
    } else {
      return {
        responseMessage: `Failed to add inventory to purchase order!`,
      };
    }
  };

  return { 
    addInventoryToPurchaseOrder, 
    addInventoryToPurchaseOrderLoading: loading, 
  };
};