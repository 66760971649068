import React, { FC, memo, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  TreeItem,
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from "@mui/lab";
import { treeItemClasses } from "@mui/lab/TreeItem";
import { Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { getIcon } from "../../utils";
import { Link } from "react-router-dom";

interface ExtendedTreeItemContentProps extends TreeItemContentProps {
  expandable?: boolean;
  onMenuItemClick?: (event: React.SyntheticEvent | Event) => void;
  url?: string;
}

const pseudoLine = {
  content: '""',
  left: 0,
  width: 18,
  height: 1,
  display: "block",
  backgroundColor: "#596e83",
  position: "absolute",
  top: 25,
  transform: "translateY(-50%)",
};

const StyledTreeItem = styled(TreeItem)(() => ({
  [`& .${treeItemClasses.label}`]: {
    fontSize: 16,
    marginLeft: 5,
  },
  [`& .${treeItemClasses.content}`]: {
    color: "#fff",
    padding: "13px 15px",
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    color: "#fff",
  },
  [`& .${treeItemClasses.group} .${treeItemClasses.root}`]: {
    position: "relative",
    "&::before": pseudoLine,
  },
  [`& .${treeItemClasses.group} .MuiButton-root`]: {
    position: "relative",
    "&::before": {
      ...pseudoLine,
      width: 25,
      top: 21,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 25,
    marginTop: 5,
    marginBottom: 5,
    borderLeft: "1px solid #596e83",
  },
}));

const CustomContent = React.forwardRef<any, ExtendedTreeItemContentProps>(
  (props, ref) => {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      expandable,
      onMenuItemClick,
      url,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleExpansionClick = useCallback(
      (event: any) => {
        handleExpansion(event);
        handleSelection(event);

        if (!expandable) {
          onMenuItemClick && onMenuItemClick(event);
        }
      },
      [handleExpansion, handleSelection, onMenuItemClick]
    );

    const nodeIcon = useCallback(() => getIcon(nodeId, "w-5 h-5"), [nodeId]);

    const renderElement = useCallback(
      () => (
        <div
          className={clsx(className, classes.root, {
            [classes.expanded]: expanded,
            [classes.selected]: selected,
            [classes.focused]: focused,
            [classes.disabled]: disabled,
          })}
          onClick={handleExpansionClick}
          ref={ref}
        >
          {!!nodeIcon && (
            <div style={{ minWidth: 20 }} className="mr-3">
              {getIcon(nodeId, "w-6 h-6")}
            </div>
          )}
          
          <Typography component="div" sx={{ whiteSpace: 'pre-wrap' }} className={classes.label}>
            {label}
          </Typography>

          <div className={classes.iconContainer}>{icon}</div>
        </div>
      ),
      [
        className,
        classes,
        expanded,
        selected,
        focused,
        disabled,
        handleExpansionClick,
        ref,
        nodeIcon,
        nodeId,
        icon,
      ]
    );

    return url ? <Link to={url}>{renderElement()}</Link> : renderElement();
  }
);

interface InjectedProps {
  expandable: boolean;
  onMenuItemClick: (event: React.SyntheticEvent | Event) => void;
  url?: string;
}

const SidebarMenuItem: FC<TreeItemProps & InjectedProps> = ({
  expandable,
  onMenuItemClick,
  url,
  ...treeProps
}) => (
  <StyledTreeItem
    data-testid="sidebar-menu-item"
    {...treeProps}
    ContentComponent={CustomContent}
    ContentProps={
      {
        expandable,
        onMenuItemClick,
        url,
      } as any
    }
  />
);

export default memo(SidebarMenuItem);
