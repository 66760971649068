import { isEmpty, isNil } from 'lodash';
import { FileExtensionType } from '../../../consts';

export const getListByTabType = (results: any, type: string) => {
  if (isEmpty(results)) return results;

  if (type === 'photo') {
    return results
      .filter((e: any) => isNil(e.deletedAt))
      .filter((result: any) => [
        FileExtensionType.JPEG,
        FileExtensionType.JPG,
        FileExtensionType.BMP,
        FileExtensionType.PNG,
        FileExtensionType.TIFF,
        FileExtensionType.TIF,
        FileExtensionType.WEBP,
        FileExtensionType.SVG,
      ].includes(result.fileExtension!));
  }
  if (type === 'attachments') {
    return results
      .filter((e: any) => isNil(e.deletedAt))
      .filter(
        (result: any) => ![
          FileExtensionType.JPEG,
          FileExtensionType.JPG,
          FileExtensionType.BMP,
          FileExtensionType.PNG,
          FileExtensionType.TIFF,
          FileExtensionType.TIF,
          FileExtensionType.WEBP,
          FileExtensionType.SVG,
        ].includes(result.fileExtension!),
      );
  }
};

export const isPhoto = (file: any) => [
  FileExtensionType.JPEG,
  FileExtensionType.JPG,
  FileExtensionType.BMP,
  FileExtensionType.PNG,
  FileExtensionType.TIFF,
  FileExtensionType.TIF,
  FileExtensionType.WEBP,
  FileExtensionType.SVG,
].includes(file.extension!.toUpperCase());

export const generateUniqueFileName = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  
  return `photo_${year}${month}${day}_${hours}${minutes}${seconds}.jpeg`;
};