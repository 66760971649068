import { gql, useQuery } from '@apollo/client';
import { List } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LIST = gql`
  query GetList($listName: String!) {
    list(listName: $listName) {
      id
      member
      listName
      t4reqd
    }
  }
`;

interface ListResult {
  data: List[];
  loading: boolean;
}

export const useGetList = ( listName: string): ListResult => {
  const { data, loading, error } = useQuery(GET_LIST, {
    variables: { listName },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  if (error) {
    logger('GetList').error(`Error fetching ${listName} list -->`, error.message);
  }

  return {
    data: data?.list || [],
    loading,
  };
};
