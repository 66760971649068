import './styles.css';
import { useState } from 'react';
import { isNil } from 'lodash';
import { Alert, Snackbar, Button } from '@mui/material';
import CheckListGrid from './CheckListGrid';
import { getDatabase } from '../../../../rxdb';
import CheckListEditPopup from './CheckListEditPopup';
import { UseFormWatch } from 'react-hook-form';
import { handleCheckItemUpsert, handleMoveItemUpsert } from '../utils';
import CheckListMovePopup from './CheckListMovePopup';
import AddIcon from '@mui/icons-material/Add';

export type AlertColor = 'success' | 'info' | 'warning' | 'error';
type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

interface Props {
  watch: UseFormWatch<any>;
  form?: any;
  initialValue: any;
  type?: string;
  disabled?: boolean;
  onChange: (field: string, value: any) => void;
  SRHKey?: string | null;
  tableName?: string;
  disableEdit?: boolean;
}

export interface ICheckListType {
  PKey: string;
  FKey?: string | null;
  EqKey?: string | null;
  fldLocHierarchy?: string | null;
  fldTitle?: string | null;
  fldDescriptions?: string | null;
  DescKey: string | null;
  fldIndex?: number | null;
  fldSRHKey?: string | null;
  Completed?: boolean | null;
  itemTasksCount?: number | null;
  category?: string | null;
  equipment?: string | null;
  location?: string | null;
}

export interface IMoveItemType {
  PKey: string;
  FKey?: string | null;
  SchedMaintKey?: string | null;
  fldSubject?: string | null;
}

const CheckListTab = ({
  watch,
  initialValue,
  disabled = false,
  onChange,
  tableName = '',
  disableEdit = false,
}: Props) => {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);
  const [isEditFormVisible, setEditFormVisible] = useState<boolean>(false);
  const [isMoveFormVisible, setMoveFormVisible] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<ICheckListType>();
  const [movableItem, setMovableItem] = useState<IMoveItemType>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleUpsert = async (data?: ICheckListType) => {
    setSaveLoading(true)
    try {
      await handleCheckItemUpsert(initialValue.PKey, data);
      setEditableItem(undefined);
      setEditFormVisible(false);
      setAlert(true);
      setAlertMessage(
        !isNil(editableItem)
          ? 'Record successfully updated'
          : 'Record successfully created',
      );
      setAlertSeverity('success');
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
    setSaveLoading(false)
  };

  const handleMoveUpsert = async (data: IMoveItemType) => {
    setSaveLoading(true)
    try {
      await handleMoveItemUpsert(data);
      setEditableItem(undefined);
      setMoveFormVisible(false);
      setAlert(true);
      setAlertMessage(`Inspections item successfully moved to '${data?.fldSubject}'`);
      setAlertSeverity('success');
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
    setSaveLoading(false)
  };

  const handleDelete = async (data: ICheckListType) => {
    const key = data.PKey;
    const db = await getDatabase();

    try {
      // Remove Check List Item
      const tblschedcheck = await db.tblschedchk
        .findOne({
          selector: {
            _id: key,
          },
        })
        .exec();

      if (isNil(tblschedcheck)) throw new Error('Check List Item not found!');
      console.log('delete tbldocumentation', tblschedcheck.PKey);
      await tblschedcheck.remove();     

      setAlert(true);
      setAlertMessage('Record successfully removed');
      setAlertSeverity('success');
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
  };

  const handleAddClick = () => {
    setEditableItem(undefined);
    setEditFormVisible(true);
    setIsEdit(false);
  };

  const onEditFormCancel = () => {
    setEditableItem(undefined);
    setEditFormVisible(false);
  };

  const onMoveFormCancel = () => {
    setEditableItem(undefined);
    setMoveFormVisible(false);
  };

  // when check's edit button clicked send data to edit popup and open edit modal
  const onEdit = (data: ICheckListType) => {
    setEditableItem(data);
    setIsEdit(true);
    setEditFormVisible(true);
  };

  const onMove = (data: IMoveItemType) => {
    setMovableItem(data);
    setIsEdit(true);
    setMoveFormVisible(true);
  };

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          {!disabled && !disableEdit && (
            <Button 
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              Add
            </Button>
          )}
        </div>
        <CheckListGrid
          relatedKey={initialValue.PKey}
          onEdit={onEdit}
          onMove={onMove}
          onDelete={handleDelete}
          disabled={disabled}
          disableEdit={disableEdit}
        />
      </div>

      <CheckListEditPopup
        watch={watch}
        initialValue={editableItem}
        onCancel={onEditFormCancel}
        onSubmit={handleUpsert}
        visible={isEditFormVisible}
        saveLoading={saveLoading}
        isEdit={isEdit}
      />

      <CheckListMovePopup
        currentSchedKey={initialValue.SchedKey}
        initialValue={movableItem}
        onCancel={onMoveFormCancel}
        onSubmit={handleMoveUpsert}
        visible={isMoveFormVisible}
        saveLoading={saveLoading}
      />

      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default CheckListTab;
