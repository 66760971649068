import { FC } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useHistory  } from 'react-router-dom';

interface InjectedProps {
  height: any;
  width: any;
  data: any[];
  itemKey: string;
  countKey: string;
}

const BarChartUI: FC<InjectedProps> = ({
  width,
  height,
  data,
  itemKey,
  countKey,
}) => {

  const history = useHistory();

  const handleBarClick = (data: any) => {
    const item = data.payload.item; // Get the item value from the clicked bar

    let route = '';
    switch (item) {
      case 'General':
        route = '/inventories/generalInventory';
        break;
      case 'Beverages':
        route = '/inventories/beverages';
        break;
      case 'Uniform':
        route = '/inventories/uniform';
        break;
      case 'Stores':
        route = '/inventories/stores';
        break;
      case 'Medical':
        route = '/inventories/medical';
        break;
      default:
        route = '/inventories'; // Default route if no match
    }

    history.push(route); // Navigate to the corresponding route
  };

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart data={data}>
        <XAxis dataKey={itemKey} />
        <YAxis />
        <Bar dataKey={countKey} fill="#e03141" onClick={(data) => handleBarClick(data)}/>
        <Tooltip
          cursor={{ fill: 'rgba(128, 128, 128, 0.3)' }}
          formatter={(value: number) => [`${value}`, 'Count']}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartUI;