import { ApolloError, gql, useMutation } from '@apollo/client';
import { BulkModifyInventoryLocationInput, InventoryLocation, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const BULK_MOVE_INVENTORY_TO_LOCATION = gql`
  mutation BulkMoveInventoryToLocation($input: BulkModifyInventoryLocationInput!) {
    bulkModifyInventoryLocation(input: $input) {
      inventoryLocations {
        id
        inventoryId
        locationId
        amount
      }
      errors {
        code
        message
      }
    }
  }
`;

export interface BulkMoveInventoryToLocationMutationResponse {
  responseData?: InventoryLocation[];
  responseErrors?: UserError;
  responseMessage: string;
}

interface BulkMoveInventoryToLocationResult {
  bulkMoveInventoryToLocation: (input: BulkModifyInventoryLocationInput) => Promise<BulkMoveInventoryToLocationMutationResponse>;
  bulkMoveInventoryToLocationLoading: boolean;
  bulkMoveInventoryToLocationError?: ApolloError;
}

export const useBulkMoveInventoryToLocation = (): BulkMoveInventoryToLocationResult => {
  const [updateMultipleInventoryLocation, { loading, error }] = useMutation(BULK_MOVE_INVENTORY_TO_LOCATION, {
    onError: (error: ApolloError) => {
      logger('BulkMoveInventoryToLocation').error("Error moving multiple inventory to location -->", error.message);
    }
  });

  const bulkMoveInventoryToLocation = async (input: BulkModifyInventoryLocationInput): Promise<BulkMoveInventoryToLocationMutationResponse> => {
    const response = await updateMultipleInventoryLocation({ 
      variables: { input },
    });

    const responseData = response.data?.bulkModifyInventoryLocation?.inventoryLocations;
    const responseErrors = response.data?.bulkModifyInventoryLocation?.errors;

    if (responseData) {
      logger('BulkMoveInventoryToLocation').info(`Successfully moved selected inventory`, response.data);
      return {
        responseData,
        responseMessage: `Successfully moved selected inventory!`
      };
    } else if (responseErrors) {
      logger('BulkMoveInventoryToLocation').info(`Successfully moved selected inventory`, response.data);
      return {
        responseErrors,
        responseMessage: `One or more of the selected inventory items already exist at the destination location; duplicates are not permitted.`
      };
    } else {
      return {
        responseMessage: `Failed to move selected inventory!`,
      };
    }
  };

  return { 
    bulkMoveInventoryToLocation, 
    bulkMoveInventoryToLocationLoading: loading, 
    bulkMoveInventoryToLocationError: error,
  };
};
