import { ApolloError, gql, useMutation } from '@apollo/client';
import { Barcode, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const ADD_BARCODE_TO_INVENTORY = gql`
  mutation AddBarcodeToInventory($input: BarcodeInput!) {
    addBarcode(input: $input) {
      barcode {
        barcodeNumber
        inventoryId
        isDefault
      }
      errors {
        code
        message
      }
    }
  }
`;

export interface AddBarcodeToInventoryMutationResponse {
  responseData?: Barcode;
  responseDataError?: UserError;
  responseMessage: string;
}

interface AddBarcodeToInventoryResult {
  addBarcodeToInventory: (barcodeNumber: string, inventoryId: string, allowDuplicates?: boolean) => Promise<AddBarcodeToInventoryMutationResponse>;
  addBarcodeToInventoryLoading: boolean;
}

export const useAddBarcodeToInventory = (): AddBarcodeToInventoryResult => {
  const [createBarcodeToInventory, { loading, error }] = useMutation(ADD_BARCODE_TO_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('AddBarcodeToInventory').error(`Error adding barcode to inventory -->`, error.message);
    },  
  });

  const addBarcodeToInventory = async (barcodeNumber: string, inventoryId: string, allowDuplicates?: boolean): Promise<AddBarcodeToInventoryMutationResponse> => {
    const response = await createBarcodeToInventory({ 
      variables: { 
        input: {
          barcodeNumber, 
          inventoryId,
          allowDuplicates
        }
      },
    });

    const responseData = response.data?.addBarcode?.barcode;
    const responseDataError = response.data?.addBarcode?.errors;

    if (responseData) {
      logger('AddBarcodeToInventory').info('Barcode added successfully', response.data);
      return {
        responseData,
        responseMessage: `Barcode added successfully!`,
      };
    } else if (responseDataError) {
      logger('AddBarcodeToInventory').info('Barcode duplicate', response.data);
      return {
        responseDataError: responseDataError[0],
        responseMessage: responseDataError[0].message,
      };
    } else {
      return {
        responseMessage: `Failed to add barcode!`,
      };
    }
  };

  return { 
    addBarcodeToInventory, 
    addBarcodeToInventoryLoading: loading, 
  };
};