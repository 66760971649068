import { ApolloQueryResult, gql, useQuery  } from "@apollo/client";
import { InventoryFilterInput, InventorySortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { InventoryResult } from "../interfaces";

export const GET_INVENTORY_FOR_LOCATION = gql`
  query GetInventoryForLocation($skip: Int, $take: Int, $filterInput: InventoryFilterInput, $order: [InventorySortInput!]) {
    inventoryForLocation: inventory(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        productName
        manufacturer
        partNumber
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export const useGetInventoryForLocation = (skip: number, take: number,  filterInput: InventoryFilterInput, order: InventorySortInput[]): InventoryResult => {

  const { data, loading, error, refetch } = useQuery(GET_INVENTORY_FOR_LOCATION, {
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  const loadData = (skip: number, take: number, filterInput: InventoryFilterInput, order: InventorySortInput[]) => {
    logger('GetInventoryForLocation').debug('Query variables', {skip: skip, take: take, filterInput: filterInput, order: order});     
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.inventoryForLocation) {
          const { items, pageInfo } = result.data.inventoryForLocation;
          logger('GetInventoryForLocation').info('Inventory for location fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetInventoryForLocation').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetInventoryForLocation').error('Error re-fetching inventory for location -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetInventoryForLocation').error('Error fetching inventory for location -->', error.message);
  }

  return {
    data: data?.inventoryForLocation?.items || [],
    totalCount: data?.inventoryForLocation?.totalCount || 0,
    currentCount: data?.inventoryForLocation?.items?.length || 0,
    pageInfo: data?.inventoryForLocation?.pageInfo,
    loading,
    loadData,
  };
};