import '../ReportsPrintButton/styles.css';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Portal, Snackbar, AlertColor } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import ForwardedPSPDFKitViewer from '../Util/PSPDFKitViewer';
import BarcodeLabel from '../BarcodePDF/jsBarcodeLabel';
import BarcodeForm from './BarcodeForm';
import { Inventory } from 'src/generated/dotnet.graphql';
import { useGetInventoryLocations } from 'src/hooks/inventory/useGetInventoryLocation';
import { useGetDefaultLabelSize } from 'src/hooks/YMSSettings/useGetDefaultLabelSize';
import { BCLSizes } from 'src/consts';

interface InjectedProps {
  inventory: Inventory;
  visible: boolean;
  onCancel?: () => void;
}

const BarcodePopup: FC<InjectedProps> = ({ inventory, visible, onCancel }) => {
  const { control, handleSubmit, setValue } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const [loading, setLoading] = useState(false);
  const childRef = useRef<{ handlePSPDFKitOpen: () => void } | null>(null);
  const [payload, setPayload] = useState<any>()
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  
  const { defaultLabelSize, defaultLabelSizeLoading } = useGetDefaultLabelSize();
  const { inventoryLocations, inventoryLocationsLoading } = useGetInventoryLocations(inventory.id);
  
  const handlePDFOpen = () => {
    childRef.current?.handlePSPDFKitOpen()
  }

  useEffect(() => {
    if (defaultLabelSize) {
      setValue('labelID', parseInt(defaultLabelSize));
    }
  }, [inventory, onCancel]);

  const getLocationName = (selectedLocationId: string) => {
    const selectedLocation = inventoryLocations?.find(location => location.id === selectedLocationId);
    return selectedLocation ? selectedLocation.locationPath : '';
  }

  const getInventoryBarcode = () => {
    const defaultInventoryBarcode = inventory?.barcodes?.find(barcode => barcode.isDefault);
    return defaultInventoryBarcode ?? null;
  }

  const getLabelSizeByLabelId = (labelID: number) => {
    const selectedLabel = BCLSizes.find((size) => size.labelID === labelID);
    const labelSize = selectedLabel
      ? { width: selectedLabel.width, height: selectedLabel.height }
      : { width: 0, height: 0 };
    return labelSize;
  }

  const onOkClicked = async (data: any) => {
    try {
      if (!loading) {
        setLoading(true)
        // retrieve user input data
        const { id, labelID } = data
        const locationName = getLocationName(id);
        const barcode = getInventoryBarcode();
        const categoryName = inventory.categoryId // TODO - display the category full path
        const labelSize = getLabelSizeByLabelId(labelID);

        const BCData = {
          // Inventory tbl
          productId: inventory?.id,
          labelStyle: '',
          productName: inventory?.productName,
          manufacturer: inventory?.manufacturer,
          modelNumber: inventory?.modelNumber,
          partNumber: inventory?.partNumber,
          categoryFullPath: categoryName,
          llbc: inventory?.llbc,
          barcode: barcode,
          locationFullPath: locationName,
          llBCdata: [
            {
              building: '',
              unit: '',
              pos: '',
            },
          ],
          luerssenStyle: false,
          labelSize: labelSize
        };

        setPayload(BCData)
        setLoading(false)
      }
      handlePDFOpen();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleGeneratePDF = () => {
    if (!payload) {
        throw new Error('Payload is not available to generate the PDF');
    }
    return BarcodeLabel(payload);
  };

  const onCancelClick = () => {
    onCancel && onCancel();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onOkClicked)}>
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          PaperProps={{style: { maxWidth: '30%' }}}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {`Barcode Print Utility`}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>

          <DialogContent dividers>
            <BarcodeForm  
              options={inventoryLocations} 
              control={control} 
            />
          </DialogContent>

          <DialogActions sx={{ m: 0, p: 3, justifyContent: 'flex-end' }}>
          <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmit(onOkClicked)}
              loading={loading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              <span>OK</span>
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <ForwardedPSPDFKitViewer 
            generatePDF={handleGeneratePDF}
            onClose={onCancelClick}
            enable={!!payload}
            ref={childRef}
            reportType='Barcode'
        />

        <Portal>
          <Snackbar
            open={!!snackbar}
            autoHideDuration={2000}
            onClose={() => setSnackbar(undefined)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default BarcodePopup;

