import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { FC, useState } from 'react';
import WarningDialog from 'src/components/UI/WarningDialog';
import LicensedReactDataGrid from '../../../UI/LicensedReactDataGrid';
import { DOWNLOAD_URL } from '../../../../consts';
import { useGetDocumentRevisions } from 'src/hooks/documentationLibraries/useGetDocumentRevisions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DocumentRevision } from 'src/generated/dotnet.graphql';

interface DocumentRevisionsGridProps {
  documentationId: string;
  onEdit: (revision: DocumentRevision) => void;
  onDelete: (revision: DocumentRevision) => void;
  readOnly?: boolean;
}

const DocumentRevisionsGrid: FC<DocumentRevisionsGridProps> = ({
  onEdit,
  onDelete,
  documentationId,
  readOnly,
}) => {
  const [isDeletingWarningPopup, setIsDeletingWarningPopup] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState<DocumentRevision>();
  const { revisions, revisionsLoading } = useGetDocumentRevisions(documentationId);

  const handleDelete = (revision: DocumentRevision) => {
    setSelectedRevision(revision);
    setIsDeletingWarningPopup(true);
  };

  const handleDeleteOk = async () =>{
    if(selectedRevision) {
      onDelete(selectedRevision);
      setIsDeletingWarningPopup(false);
      setSelectedRevision(undefined);
    }
  };

  const handleDeleteCancel = () =>{
    setIsDeletingWarningPopup(false);
    setSelectedRevision(undefined);
  };

  const onDocumentView = (revision: DocumentRevision) => {
    if(revision && revision.fileName) {
      const fileUrl = `${DOWNLOAD_URL}/${encodeURIComponent(revision.fileName)}`;
      window.open(fileUrl, "_blank", "noopener,noreferrer");
    }
  }

  const columns = [
    {
      name: 'revisionDate',
      header: 'Rev Date',
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      flex: 0.5,
      render: ({ data }: any) => {
        if (isNil(data.revisionDate)) return null;

        return format(new Date(data.revisionDate), 'dd-MMM-yyyy');
      },
    },
    {
      name: 'revision',
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      header: 'Rev #',
      flex: 0.3,
    },
    {
      name: 'fileName',
      header: 'File Name',
      flex: 1,
    },
    {
      name: 'actions',
      sortable: false,
      header: 'Actions',
      flex: 0.3,
      render: ({ data }: any) => {
        return (
          <div className = "flex justify-center">
              <IconButton
                onClick={(event) => {
                  onDocumentView(data);
                  event.stopPropagation();
                }}
                size="small"
                aria-label="View item"
                className="mx-2"
              >
                <VisibilityIcon fontSize="inherit" />
              </IconButton>

            {!readOnly && 
              <IconButton
                aria-label="edit"
                size="small"
                onClick={() => onEdit(data)}
              >
                <EditOutlinedIcon color="primary" fontSize="inherit" />
              </IconButton>
            }

            {!readOnly &&
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() =>  handleDelete(data)}
              >
                <DeleteTwoTone color="error" fontSize="inherit" />
              </IconButton>
            }
          </div>
        )
      }
    }
  ];

  return (
    <>
      <div
        data-testid="data-grid"
        className="flex flex-col flex-grow retaled-tab-grid"
      >
        <LicensedReactDataGrid
          rowHeight={40}
          loading={revisionsLoading}
          showColumnMenuTool={false}
          idProperty="id"
          columns={columns}
          dataSource={revisions}
          defaultSortInfo={[{ name: 'revisionDate', dir: -1 }]}
        />
      </div>

      <WarningDialog
        visible={isDeletingWarningPopup}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default DocumentRevisionsGrid;
