import { ApolloError, gql, useMutation } from '@apollo/client';
import { DeleteResult, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const REMOVE_BARCODE_FROM_INVENTORY = gql`
  mutation RemoveBarcodeFromInventory($input: RemoveBarcodeInput!) {
    removeBarcode(input: $input) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

export interface InventoryRemoveBarcodeMutationResponse {
  responseData?: DeleteResult;
  responseDataError?: UserError;
  responseMessage: string;
}

interface RemoveBarcodeToInventoryResult {
  removeBarcodeFromInventory: (barcodeNumber: string, inventoryId: string) => Promise<InventoryRemoveBarcodeMutationResponse>;
  removeBarcodeFromInventoryLoading: boolean;
}

export const useRemoveBarcodeToInventory = (): RemoveBarcodeToInventoryResult => {
  const [deleteBarcodeFromInventory, { loading, error }] = useMutation(REMOVE_BARCODE_FROM_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('RemoveBarcodeFromInventory').error(`Error removing barcode from inventory -->`, error.message);
    },  
  });

  const removeBarcodeFromInventory = async (barcodeNumber: string, inventoryId: string): Promise<InventoryRemoveBarcodeMutationResponse> => {
    const response = await deleteBarcodeFromInventory({ 
      variables: { 
        input: {
          barcodeNumber, 
          inventoryId,
        }
      },
    });

    const responseData = response.data?.removeBarcode;

    if (responseData) {
      logger('RemoveBarcodeFromInventory').info('Barcode removed successfully', response.data);
      return {
        responseData: responseData?.deleteResult,
        responseDataError: responseData?.errors,
        responseMessage: responseData?.deleteResult?.message,
      };
    } else {
      return {
        responseMessage: `Failed to add barcode!`,
      };
    }
  };

  return { 
    removeBarcodeFromInventory, 
    removeBarcodeFromInventoryLoading: loading, 
  };
};