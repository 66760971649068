import { ApolloError, gql, useMutation } from '@apollo/client';
import { DashboardTileInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INFO_TILE } from './useGetInfoTile';
import { DashboardTileMutationResponse } from '../interfaces';

export const UPDATE_INFO_TILE = gql`
  mutation UpdateInfoTile($input: DashboardTileInput!) {
    modifyDashboardTile(input: $input) {
      dashboardTile {
        contents
        title
      }
      errors {
        code
        message
      }
    }
  }
`;

interface UpdateInfoTileResult {
  updateInfoTile: (input: DashboardTileInput) => Promise<DashboardTileMutationResponse>;
  updateInfoTileLoading: boolean;
}

export const useUpdateInfoTile = (): UpdateInfoTileResult => {
  const [modifyInfoTile, { loading, error }] = useMutation(UPDATE_INFO_TILE, {
    onError: (error: ApolloError) => {
      logger('UpdateInfoTile').error(`Error updating Info Tile -->`, error.message);
    },  
  });

  const updateInfoTile = async (input: DashboardTileInput): Promise<DashboardTileMutationResponse> => {
    const response = await modifyInfoTile({ 
      variables: { input },
      refetchQueries: [{
        query: GET_INFO_TILE
      }],
    });

    const responseData = response.data?.modifyDashboardTile?.dashboardTile;

    if (responseData) {
      logger('UpdateInfoTile').info('Info Tile updated successfully', response.data);
      return {
        responseData,
        responseMessage: `Info Tile updated successfully!`,
      };
    } else {
      return {
        responseMessage: `Failed to update Info Tile!`,
      };
    }
  };

  return { 
    updateInfoTile, 
    updateInfoTileLoading: loading, 
  };
};