import { ApolloError, gql, useMutation } from '@apollo/client';
import { RemoveInventoryFromLocationInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INVENTORY_LOCATIONS } from './useGetInventoryLocation';
import { InventoryDeleteMutationResponse } from './useDeleteInventory';


export const DELETE_INVENTORY_LOCATION = gql`
  mutation DeleteInventoryLocation($input: RemoveInventoryFromLocationInput!) {
    removeInventoryFromLocation(input: $input) {
      deleteResult {
        deletedIds
        message
        success
      }
    }
  }
`;

interface DeleteInventoryLocationResult {
  removeInventoryLocation: (input: RemoveInventoryFromLocationInput) => Promise<InventoryDeleteMutationResponse>;
  removeInventoryLocationLoading: boolean;
}

export const useDeleteInventoryLocation = (): DeleteInventoryLocationResult => {
  const [deleteInventoryLocation, { loading }] = useMutation(DELETE_INVENTORY_LOCATION, {
    onError: (error: ApolloError) => {
      logger('DeleteInventoryLocation').error('Error deleting inventory from location -->', error.message);
    }
  });

  const removeInventoryLocation = async (input: RemoveInventoryFromLocationInput): Promise<InventoryDeleteMutationResponse> => {
    const response = await deleteInventoryLocation({ 
      variables: { input },
      refetchQueries: [{
        query: GET_INVENTORY_LOCATIONS,
        variables: { inventoryId: input.inventoryId }
      }],
    });
    const responseData = response.data?.removeInventoryFromLocation?.deleteResult;
    if (responseData?.success) {
      logger('DeleteInventoryLocation').info('Inventory removed from location successfully', response.data);
      return {
        responseData,
        responseMessage: `Inventory removed from location successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove inventory from location!`,
      };
    }
  };

  return { 
    removeInventoryLocation, 
    removeInventoryLocationLoading: loading, 
  };
};
