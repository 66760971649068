import { FC } from 'react';
import { Control, RegisterOptions } from 'react-hook-form';
import TreeSelect from '../../UI/Forms/TreeSelect/index';
import { useAuth } from '../../../contexts/auth';
import { useGetCategories } from 'src/hooks/categories/useGetCategories';
import { RecordType } from 'src/generated/dotnet.graphql';

type CategoryProps = {
  label: string;
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  rules?: RegisterOptions;
  defaultValue?: string | null;
  disabled?: boolean;
  recordType?: RecordType;
  allDepts?: boolean;
  actionable?: boolean;
};

const CategoryDropdown: FC<CategoryProps> = ({
  control,
  name,
  label,
  onChange,
  rules,
  defaultValue,
  disabled,
  recordType,
  allDepts = false,
  actionable,
}) => {
  const { user } = useAuth();
  const { data } = useGetCategories();

  const handleFilter = (item: any) => {
    switch (recordType) {
      case RecordType.WorkIssue:
        return getTwoNotesCondition(item, 'WORKISSUES');
      case RecordType.LogEntry:
        return getTwoNotesCondition(item, 'LOGENTRY');
      case RecordType.Equipment:
        return getTwoNotesCondition(item, 'EQUIPMENT');
      case RecordType.EventSchedule:
        return getTwoNotesCondition(item, 'EVENT_SCHEDULING');
      case RecordType.DrillSchedule:
        return getTwoNotesCondition(item, 'DRILL_SCHEDULING');
      case RecordType.InventoryGeneral:
        return getTwoNotesCondition(item, 'INVENTORY');
      case RecordType.InventoryBeverages:
        return getOneNoteCondition(item, 'BEVERAGES');
      case RecordType.InventoryUniform:
        return getOneNoteCondition(item, 'UNIFORMS');
      case RecordType.InventoryDryAndColdStore:
        return getOneNoteCondition(item, 'STORES');
      case RecordType.InventoryMedical:
        return getOneNoteCondition(item, 'MEDICAL');
      case RecordType.InspectionSchedule:
        return getTwoNotesCondition(item, 'INSPECTIONS');
      case RecordType.DocumentationSms:
        return getOneNoteCondition(item, 'SMSDOCS');
      case RecordType.DocumentationIsps:
        return getOneNoteCondition(item, 'ISPSDOCS');
      default:
        return true;
    }
  };

  const getOneNoteCondition = (item: any, field: string) => {
    if (item.tags && item.tags.includes(field)) {
      return true;
    }
    return false;
  };

  const getTwoNotesCondition = (item: any, field: string) => {
    if (allDepts) {
      if (!item.tags || item.tags.includes(field)) {
        return true;
      }
      return false;
    }
    if (
      (!item.tags || item.tags.includes(field)) && (!item.depts || item.depts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };

  const categories = data.filter(handleFilter).map((elem: any) => ({
    id: elem.id,
    parentId: elem.parentId,
    category: elem.category,
    expanded: false,
  }));

  return (
    <TreeSelect
      disabled={disabled}
      onChange={onChange}
      options={categories}
      control={control}
      name={name}
      valueExpr="id"
      keyExpr="id"
      parentIdExpr="parentId"
      displayExpr="category"
      label="Category"
      rules={rules}
      actionable={actionable}
    />
  );
};

export default CategoryDropdown;
