import { ApolloQueryResult, gql, useQuery  } from "@apollo/client";
import { Inventory, CollectionSegmentInfo, InventoryFilterInput, InventorySortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { InventoryResult } from "../interfaces";

export const GET_INVENTORY = gql`
  query GetInventory($skip: Int, $take: Int, $filterInput: InventoryFilterInput, $order: [InventorySortInput!]) {
    inventory(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        barcodes {
          barcodeNumber
          isDefault
        }
        productName
        manufacturer
        partNumber
        modelNumber
        categoryId
        department
        consumable
        bonded
        sms
        country
        region
        color
        size
        reOrderLevel
        reOrderAmt
        factor
        qty
        rx
        orderQty
        productDescription
        documentsCount
        photosCount
        commentsCount
        equipmentCount
        ordersCount
        totalAmount
        lowStock
        expiredAtLocation
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export const useGetInventory = (skip: number, take: number,  filterInput: InventoryFilterInput, order: InventorySortInput[]): InventoryResult => {

  const { data, loading, error, refetch } = useQuery(GET_INVENTORY, {
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const loadData = (skip: number, take: number, filterInput: InventoryFilterInput, order: InventorySortInput[]) => {
    logger('GetInventory').debug('Query variables', {skip: skip, take: take, filterInput: filterInput, order: order});     
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.inventory) {
          const { items, pageInfo } = result.data.inventory;
          logger('GetInventory').info('Inventory fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetInventory').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetInventory').error('Error re-fetching inventory -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetInventory').error('Error fetching inventory -->', error.message);
  }

  return {
    data: data?.inventory?.items || [],
    totalCount: data?.inventory?.totalCount || 0,
    currentCount: data?.inventory?.items?.length || 0,
    pageInfo: data?.inventory?.pageInfo,
    loading,
    loadData,
  };
};