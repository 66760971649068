import { gql, useQuery } from '@apollo/client';
import { GroupCountOfNullableOfInventoryType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_INVENTORY_LOW_STOCK_COUNTS = gql`
  query GetInventoryLowStockCounts {
    inventoryLowStockCounts {
      count
      item
    }
  }
`;

interface InventoryLowStockCountsResult {
  data: GroupCountOfNullableOfInventoryType[];
  loading: boolean;
}

export const useGetInventoryLowStockCounts = (): InventoryLowStockCountsResult => {
  const { data, loading, error } = useQuery(GET_INVENTORY_LOW_STOCK_COUNTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  if (error) {
    logger('GetInventoryLowStockCounts').error('Error fetching inventory low stock counts -->', error.message);
  }

  return {
    data: data?.inventoryLowStockCounts || [],
    loading,
  };
};
