import { gql, useQuery } from '@apollo/client';
import { Order } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_PURCHASE_ORDERS = gql`
  query GetPurchaseOrders {
    purchaseOrders {
      id
      orderName
    }
  }
`;

interface PurchaseOrderResult {
  purchaseOrders: Order[];
  purchaseOrdersLoading: boolean;
}

export const useGetPurchaseOrders = (): PurchaseOrderResult => {
  const { data, loading, error } = useQuery(GET_PURCHASE_ORDERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  if (error) {
    logger('GetPurchaseOrders').error('Error fetching purchase orders -->', error.message);
  }

  return {
    purchaseOrders: data?.purchaseOrders || [],
    purchaseOrdersLoading: loading,
  };
};
