import { FC, useEffect, useState } from 'react';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Input from 'src/components/UI/Forms/Input';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { useForm } from 'react-hook-form';
import { DashboardTile, DashboardTileInput } from 'src/generated/dotnet.graphql';
import { size } from 'lodash';

interface InjectedProps {
  visible: boolean;
  initialValue: DashboardTile;
  onSave: (data: DashboardTileInput) => void;
  onCancel: () => void;
}

const InfoEditDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
}) => {
  const { control, setValue, handleSubmit, formState } = useForm<any>({
    defaultValues: {
      title: initialValue?.title,
      contents: initialValue?.contents,
    },
  });
  const [saveButtonVisible, setSaveButtonVisible] = useState<boolean>(false);

  const handleSave = async (data: any) => {
    onSave(data);
  };

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  useEffect(() => {
    const hasValuesBeenChanged = formState.isDirty && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
    setSaveButtonVisible(hasValuesBeenChanged ? true : false);
  }, [formState])

  return (
    <Dialog
      scroll="paper"
      fullWidth
      maxWidth="md"
      open={visible}
      onClose={onCancel}
    >
      <DialogTitle>
        <span className="font-bold text-2xl">Edit Tile Contents</span>
      </DialogTitle>

      <DialogContent 
        dividers    
        sx={{p: 0, paddingBottom: 4}}
      >

        <form
          id="Info-Edit-Form"
          className="relative bg-white flex-grow"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="bg-white h-full flex-grow pt-6">
            <div className="px-6 h-full">
              <Input
                inputProps={{
                  label: 'Title',
                }}
                control={control}
                name="title"
              />

              <div className="mt-4">
                <RichTextEditor
                  control={control}
                  name="contents"
                  onChange={onChange}
                />
              </div>
    
            </div>
          </div>
        </form>

      </DialogContent>
      
      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        <Box
          sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
        >
          {!saveButtonVisible && (
            <Button 
              variant="contained" 
              className="mr-2" 
              onClick={onCancel}
            >
              Close
            </Button>
          )}
          {saveButtonVisible && (
            <div>
              <Button 
                className="mr-2" 
                onClick={onCancel}
              >
                Cancel
              </Button>

              <LoadingButton
                type="submit"
                form="Info-Edit-Form"
                variant="contained"
              >
                Save
              </LoadingButton>
            </div>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InfoEditDialog;
