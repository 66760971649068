import { ApolloError, gql, useMutation } from '@apollo/client';
import { InventoryLocationInput, InventoryLocation } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const BULK_ADD_INVENTORY_TO_LOCATION = gql`
  mutation BulkAddInventoryToLocation($input: [InventoryLocationInput!]!) {
    bulkAddInventoryToLocation(input: $input) {
      inventoryLocations {
        id
        inventoryId
        locationId
      }
    }
  }
`;

export interface BulkAddInventoryToLocationMutationResponse {
  responseData?: InventoryLocation[];
  responseMessage: string;
}

interface BulkAddInventoryToLocationResult {
  bulkAddInventoryToLocation: (input: InventoryLocationInput[]) => Promise<BulkAddInventoryToLocationMutationResponse>;
  bulkAddInventoryToLocationLoading: boolean;
  bulkAddInventoryToLocationError?: ApolloError;
}

export const useBulkAddInventoryToLocation = (): BulkAddInventoryToLocationResult => {
  const [addMultipleInventoryToLocation, { loading, error }] = useMutation(BULK_ADD_INVENTORY_TO_LOCATION, {
    onError: (error: ApolloError) => {
      logger('BulkAddInventoryToLocation').error("Error adding multiple inventory to location -->", error.message);
    }
  });

  const bulkAddInventoryToLocation = async (input: InventoryLocationInput[]): Promise<BulkAddInventoryToLocationMutationResponse> => {
    const response = await addMultipleInventoryToLocation({ 
      variables: { input },
    });

    const responseData = response.data?.bulkAddInventoryToLocation?.inventoryLocations;

    if (responseData) {
      logger('BulkAddInventoryToLocation').info(`Successfully added inventory to location`, response.data);
      return {
        responseData,
        responseMessage: `Successfully added inventory to location!`
      };
    } else {
      return {
        responseMessage: `Failed to add selected inventory!`,
      };
    }
  };

  return { 
    bulkAddInventoryToLocation, 
    bulkAddInventoryToLocationLoading: loading, 
    bulkAddInventoryToLocationError: error,
  };
};
