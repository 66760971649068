import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, AlertColor, Snackbar, Alert } from '@mui/material';
import Input from 'src/components/UI/Forms/Input';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import { normalizeDateFormValue } from 'src/helpers';
import { useAppState } from 'src/contexts/app-state';
import { handleCharLimitWarning } from 'src/utils';
import { CHAR_LIMIT, SEVERITY } from 'src/consts';
import { isValidDateFormat } from 'src/utils/format-dates';
import LocationDropdown from 'src/components/Dropdowns/LocationDropdown';
import { Inventory, InventoryLocation, InventoryLocationInput, RecordType } from 'src/generated/dotnet.graphql';
import { isArray, isNil, last } from 'lodash';
import { useUpsertInventoryLocation } from 'src/hooks/inventory/useUpsertInventoryLocation';

interface InjectedProps {
  visible: boolean;
  storageLocation: InventoryLocation | undefined;
  inventory: Inventory | undefined;
  isCreate: boolean;
  onCancel: () => void;
  onSave: () => void;
  refreshGridView?: () => void;
  recordType: RecordType;
}

const formDefaultValues = {
  id: '',
  locationId: '',
  serialNum: '',
  amount: 0,
  max: 0,
  useBy: null,
  isDefault: false,
};

const InventoryStorageDialog: FC<InjectedProps> = ({
  visible,
  storageLocation,
  inventory,
  isCreate,
  onSave,
  onCancel,
  refreshGridView,
  recordType,
}) => {
  const { control, setValue, handleSubmit, reset } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const { settingsPersonal } = useAppState();
  const { MULTIPLELOCATIONS } = CHAR_LIMIT;
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { upsertInventoryLocation } = useUpsertInventoryLocation();

  useEffect(() => {
    setDefaultValues();
  }, [storageLocation]);

  const setDefaultValues = async () => {
    if (isNil(storageLocation)) return reset(formDefaultValues);

    const payload = {
      id: storageLocation?.id,
      locationId: storageLocation?.locationId,
      serialNum: storageLocation?.serialNum,
      amount: storageLocation?.amount,
      max: storageLocation?.max,
      useBy: normalizeDateFormValue(storageLocation?.useBy),
      isDefault: storageLocation?.isDefault,
    };

    updateFormData(payload);
  };

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleSave = async (data: InventoryLocation) => {
    if (!inventory) return;
    
    const payload: InventoryLocationInput = {
      id: data.id ? data.id : null,
      locationId: isArray(data.locationId) ? last(data.locationId) : data.locationId,
      inventoryId: inventory.id,
      serialNum: data.serialNum,
      amount: Number(data.amount),
      max: Number(data.max),
      useBy: data.useBy,
      isDefault: data.isDefault,
    };

    const { responseData, responseDataError, responseMessage } = await upsertInventoryLocation(payload, isCreate);
  
    if (responseData) {
      onSave();
      refreshGridView && refreshGridView();
    } 
    
    if (responseDataError) {
      handleCancel();
    }
  
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <>
      <form
        className="relative bg-white flex-grow"
      >
        <Dialog
          scroll="paper"
          fullWidth
          maxWidth="md"
          open={visible}
          onClose={handleCancel}
        >
          <DialogTitle>
            <span className="font-bold text-2xl">{`${storageLocation?.id ? 'Edit' : 'Add'} storage location`}</span>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 4 }}>
            <div>
              <div className="pt-5">
                <LocationDropdown
                  name="locationId"
                  control={control}
                  onChange={onChange}
                  recordType={recordType}
                  label="Location"
                  allDepts={settingsPersonal?.fldAllDepts > 0}
                  actionable
                />
              </div>
              <div className="pt-5">
                <Input
                  name="serialNum"
                  control={control}
                  rules={{ maxLength: MULTIPLELOCATIONS.SerialNum }}
                  inputProps={{ label: 'Serial Number' }}
                  warning={(value) => handleCharLimitWarning(value, MULTIPLELOCATIONS.SerialNum)}
                />
              </div>
              <div className="pt-5 w-1/4">
                <Input
                  name="amount"
                  control={control}
                  rules={{ min: 0 }}
                  inputProps={{
                    label: 'Amount',
                    type: 'number',
                    inputProps: {
                      min: storageLocation?.amount ?? 0,
                      inputMode: 'decimal',
                      pattern: '([0-9]+)?[,\\.]?[0-9]*',
                      step: 1,
                      style: { textAlign: 'end' },
                    },
                  }}
                />
              </div>
              <div className="pt-5 w-1/4">
                <Input
                  name="max"
                  control={control}
                  rules={{ min: 0 }}
                  inputProps={{
                    label: 'Max',
                    type: 'number',
                    inputProps: {
                      min: 0,
                      style: { textAlign: 'end' },
                    },
                  }}
                />
              </div>
              <div className="pt-5">
                <DatePicker
                  name="useBy"
                  control={control}
                  label="Expire Date"
                  rules={{ validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy' }}
                />
              </div>
              <div className="pt-5">
                <Checkbox
                  name="isDefault"
                  control={control}
                  label="This is the default location"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
            <Box
              sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
            >
              <Button onClick={handleSubmit(handleSave)} className="mr-3" variant="contained">
                Save
              </Button>
              <Button className="mr-2" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default InventoryStorageDialog;
