import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PageHeader from 'src/components/UI/PageHeader';
import './home.scss';
import { useAppState } from 'src/contexts/app-state';
import InventoryChartTile from './component/InventoryTile/InventoryChartTile';
import EquipmentChartTile from './component/EquipmentChartTile';
import VesselTile from './component/VesselTile';
import InfoTile from './component/InfoTile/InfoTile';
import { useGetDashboardTileEnabled } from 'src/hooks/YMSSettings/useGetDashboardTileEnabled';

const Home = () => {
  const [equipmentTileDisplay, seEquipmentTileDisplay] = useState(false);
  const [inventoryTileDisplay, setInventoryTileDisplay] = useState(false);
  const { dashboardTileEnabled, dashboardTileEnabledLoading } = useGetDashboardTileEnabled();
  const { settingsPersonal } = useAppState();

  const init = async () => {
    if (settingsPersonal.fldEQ != null) {
      if (settingsPersonal.fldNoRights != true && settingsPersonal?.fldEQ > 0) {
        seEquipmentTileDisplay(true);
      }
    }
    if (settingsPersonal.fldInv != null) {
      if (settingsPersonal.fldNoRights != true && settingsPersonal?.fldInv > 0) {
        setInventoryTileDisplay(true);
      }
    }
  };

  useEffect(() => {
    if (settingsPersonal) {
      init();
    }
  }, [settingsPersonal]);

  return (
      <div className="flex flex-col h-screen overflow-hidden">
        <PageHeader title="Dashboard" />
        <div className="p-1 flex-grow overflow-auto">
          <Grid 
            container 
            spacing={3}
            className='custom-scrollbar' 
          >
            {dashboardTileEnabled && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoTile />
              </Grid>
            )}
            {inventoryTileDisplay && (
              <Grid item xs={12} sm={6} lg={4}>
                <InventoryChartTile />
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={4}>
              <VesselTile />
            </Grid>
            {equipmentTileDisplay && (
              <Grid item xs={12} sm={6} lg={4}>
                <EquipmentChartTile />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
  );
};

export default Home;
