import './style.css'
import { useState, FC, useRef, useCallback, MutableRefObject, useEffect } from 'react';
import { isEmpty, isNil, pick, size } from 'lodash';
import { Alert, Snackbar, IconButton, useMediaQuery, Button } from '@mui/material';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import EqSapreInventoryEditDialog from './EqSapreInventoryEditDialog';
import WarningDialog from 'src/components/UI/WarningDialog';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { getDatabase, TDIDb } from 'src/rxdb';
import { InvEqDocument } from 'src/rxdb/collections/InvEq/schema';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import EqSpareAddDialog from './EqSpareAddDialog';
import {getRegexByOperator} from "../../../../utils";
import AddIcon from '@mui/icons-material/Add';

interface Props {
  initialValue: EquipmentDocument;
  disableEdit: boolean;
  editFlag: boolean;
}

const filter = [
  {
    name: 'ProductName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldPartNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'ModelNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const transformData = async (item: any) => {
  const product = await item.populate('ProductID');

  return {
    ...pick(item, ['EqKey', 'ProductID']),
    ProductName: product?.ProductName,
    Manufacturer: product?.Manufacturer,
    fldPartNumber: product?.fldPartNumber,
    ModelNumber: product?.ModelNumber,
    deletedAt: product?.deletedAt,
    original: item,
  };
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'ProductName':
      const productname = await db.inventory
        .find({
          selector: {
            ProductName: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: productname.map((e) => e.primary) },
      };

    case 'Manufacturer':
      const manufacturers = await db.inventory
        .find({
          selector: {
            Manufacturer: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: manufacturers.map((e) => e.primary) },
      };

    case 'fldPartNumber':
      const fldPartNumbers = await db.inventory
        .find({
          selector: {
            fldPartNumber: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: fldPartNumbers.map((e) => e.primary) },
      };

    case 'ModelNumber':
      const modelNumbers = await db.inventory
        .find({
          selector: {
            ModelNumber: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: modelNumbers.map((e) => e.primary) },
      };

    default:
      return {};
  }
};

const getKeys = async (item: any) => {
  const n = { ...pick(item, ['ProductID']) };
  return [n.ProductID];
};

const EqSpares: FC<Props> = ({ initialValue, disableEdit = false, editFlag = false }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);
  const [selectedInventory, setSelectedInventory] = useState<InventoryDocument>();
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<any>();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)')

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      EqKey: initialValue.EqKey,
      deletedAt: {
        $eq: null
      },
    };

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db,
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      }),
    );

    const items = await db.inveq
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

      
    const rawData = await Promise.all(items.map(transformData));
    // Filter out deleted inventory item
    const data = rawData.filter(spare => spare.deletedAt === null)
    const length = size(data);

    const dataKey = await Promise.all(items.map(getKeys));
    let newDataKey: any = [];

    dataKey.map((a: any) => {
      newDataKey = [...newDataKey, ...a];
      setGridData(newDataKey);
    });

    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.inveq.$.subscribe(async (ev: any) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation==='DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  useEffect(() => {
    if (isNil(gridRef)) return;
    init(gridRef);
  }, [gridRef]);

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const handleDelete = async (item: InvEqDocument) => {
    try {
      await item.remove();
      setSnackbar({
        open: true,
        message: 'Spare successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  };

  const rowDeletePress = (item: any) => {
    setDeleteSelected(item.original);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    handleDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  }

  const rowEdit = async (item: any) => {
    const db = await getDatabase();

    const inventoryItem: any = await db.inventory
      .find({
        selector: {
          ProductID: item.ProductID,
        },
      })
      .exec();

    setSelectedInventory(inventoryItem[0]);
    setEditPopupVisible(true);
  };

  const editSave = () => {
    setSelectedInventory(undefined);
    setEditPopupVisible(false);
    setSnackbar({
      open: true,
      message: 'Inventory has been updated!',
      type: 'success',
    });
  };

  const editCancel = () => {
    setSelectedInventory(undefined);
    setEditPopupVisible(false);
  };

  const handleAddClick = () => {
    setAddPopupVisible(true);
  };

  const handleAddCancel = () => {
    setAddPopupVisible(false);
  };

  const handleAddSave = () => {
    setAddPopupVisible(false);
    setSnackbar({
      open: true,
      type: 'success',
      message: 'Spares has been added!',
    });
  };

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: 'ProductName',
      header: 'Spares',
      flex: 1,
    },
    {
      name: 'Manufacturer',
      header: 'Manufacturer',
      flex: 0.5,
      visible: !isMobile,
    },
    {
      name: 'fldPartNumber',
      header: 'Part Number',
      flex: 0.5,
      visible: !(isTablet || isMobile)
    },
    {
      name: 'ModelNumber',
      header: 'Model Number',
      flex: 0.5,
      visible: !(isTablet || isMobile)
    },
    {
      id: 'actions',
      header: 'Actions',
      flex: 0.4,
      render: ({ data }: any) => (
        <div className = "flex justify-center">
          <IconButton
            onClick={() => rowEdit(data)}
            size="small"
            aria-label="Edit item"
            disabled={disableEdit}
          >
            <Edit fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => rowDeletePress(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            disabled={disableEdit}
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          <Button 
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick} 
            disabled={disableEdit}
          >
            Add
          </Button>
        </div>
        <div data-testid="data-grid" className="flex flex-col flex-grow eq-spares">
          <LicensedReactDataGrid
            onLoadingChange={onLoadingChange}
            defaultLimit={100}
            livePagination
            onReady={onReady}
            rowHeight={40}
            loading={loading}
            defaultFilterValue={filter}
            idProperty="EqKey"
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </div>
      <EqSapreInventoryEditDialog
        visible={editPopupVisible}
        initialValue={selectedInventory}
        onSave={editSave}
        onCancel={editCancel}
        editFlag={editFlag}
      />
      <EqSpareAddDialog
        initialValue={initialValue}
        visible={addPopupVisible}
        eqKeyData={gridData}
        onSave={handleAddSave}
        onCancel={handleAddCancel}
      />
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EqSpares;
