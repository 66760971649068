// This group of imports should be in every data detail component
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, omit, isEmpty, isArray, isString, isNaN } from 'lodash';
import { RxDocument } from 'rxdb';

// Third party components go here
// check here for @mui/icon-materials values https://mui.com/components/material-icons/
import { Undo, DeleteTwoTone } from '@mui/icons-material';
import { Icon, Alert, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Subscription } from 'rxjs';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { InventoryDocMethods, InventoryDocType } from 'src/pages/InventoryPage/rxdb';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';

// images
import LogIconAdd from '../../../assets/icon-log-add-primary.svg';
import TaskIconAdd from '../../../assets/icon-tasks-add-primary.svg';

// Reusable module components go here
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT, equipmentDeleteWarningMessage } from '../../../consts';
import EqSpares from './component/EqSpares';

// All module specific components go here
import { Equipment } from '../../../generated/graphql';
import EqSummaryForm from './component/EqSummaryForm';
import EqOutStandingTasks from './component/EqOutStandingTasks';
import { validateForm } from './utils';
import EqNewTaskDialog from './component/EqNewTaskDialog';
import HistoryTab from 'src/components/HistoryTab';
import EqHoursUtility from './component/EqHoursUtility';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { filterNonNullStrings, handleCharLimitWarning, removeNonAlphanumericChars } from 'src/utils';
import Schedules from "../../../modules/Schedules";
import ScheduleTabButton from "../../../modules/Schedules/components/ScheduleTabButton";
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import CompaniesDropdownOld from 'src/components/Dropdowns/CompaniesDropdown/indexOld';
import LogEntryFormDialog from '../../PageDrawer/LogEntry/LogEntryFormDialog';
import { AttachmentType, LogEntry, RecordType } from 'src/generated/dotnet.graphql';
import { AccessType, getAccessType } from 'src/utils/permissions';
import { useGetEquipmentById } from 'src/hooks/equipment/useGetEquipmentById';
import AttachmentTab from 'src/modules/Attachments';
import Comments from 'src/modules/Comments';

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: EquipmentDocument;
  onCancel: () => void;
  onSave: (eqitem: Equipment, isCreated: boolean, type?: string) => void;
  onDelete: (eqitem: EquipmentDocument) => void;
  onUndo?: () => void;
  type?: string;
  moduleReadOnly: boolean;
  isCreate?: boolean;
}

const EqDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  type,
  moduleReadOnly = false,
  isCreate = false,
  setFormIsDirty,
}) => {
  const classes = useStyles();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
    register,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      UniqueName: initialValue.UniqueName || '',
      Manufacturer: initialValue.Manufacturer,
      ModelNumber: initialValue.ModelNumber,
      Hours: initialValue.Hours || 0,
      fldCountHours: initialValue.fldCountHours|| false,
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      Department: initialValue?.Department || null,
      fldReportingType: initialValue?.fldReportingType || null,
      fldRestricted: initialValue.fldRestricted || null,
      fldSMS: initialValue.fldSMS || false,
      Notes: initialValue.Notes || '',
      Supplier: initialValue.Supplier,
      fldInService: normalizeDateFormValue(initialValue.fldInService),
      fldExpireDate: normalizeDateFormValue(initialValue.fldExpireDate),
      SerialNum: initialValue.SerialNum,
      ArrangementNum: initialValue.ArrangementNum,
      Rating: initialValue.Rating,
      ArtNumber: initialValue.ArtNumber,
      DateEntered: normalizeDateFormValue(initialValue?.DateEntered),
    },
  });

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const [oldEqUndo, setoldEqUndo] = useState<Equipment[]>([]);
  const [eqitem, setEqItem] = useState<Equipment>(initialValue.toJSON());
  const [spareCount, setSpareCount] = useState<number>(0);
  const [outStandingTasksCount, setOutStandingTasksCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [workIssueDialogVisible, setWorkIssueDialogVisible] = useState<boolean>(false);
  const [selectedWorkIssue, setSelectedWorkIssue] = useState<WorkIssuesDocument>();
  const [open, setOpen] = React.useState(false);
  const [hoursDialog, setHoursDialog] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Temporary getting the equipment by ID for populating LogEntry form on "Add LogEntry" until refactoring equipment module with the new .net api
  const { getEquipmentById } = useGetEquipmentById();
  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry>();
  const [isLogEntryCreate, setIsLogEntryCreate] = useState<boolean>(false);

  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();
  const [historyCount, setHistoryCount] = useState<number>();

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.Department){
      setRecordReadOnly(true)
    }
    if(!settingsPersonal.fldDeptHead && initialValue.fldRestricted) {
      setRecordReadOnly(true)
    }
  }

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission()
    }
  },[settingsPersonal])

  const handleCreateLogEntry = async () => {
    setIsLogEntryCreate(true);

    // Temporary getting the equipment by ID for populating LogEntry form on "Add LogEntry" until refactoring equipment module with the new .net api
    const queryResult = await getEquipmentById({ variables: {eqKey: initialValue?.EqKey } });
    const equipmentById = queryResult?.data?.equipmentById;

    // TODO - revise initailValue's fields naming after refactor into dotnet api
    const logEntry: any = {
      logDate: new Date().toISOString(),
      performedBy: `${user?.fldFirst} ${user?.fldLast}`,
      equipment: equipmentById,
      department: initialValue?.Department,
      categoryId: initialValue.fldSRHKey,
      locationId: initialValue.fldLocHierarchy,
      equipmentId: initialValue?.EqKey,
    };
    setSelectedLogEntry(logEntry);
  };

  const handleSaveLogEntry = (responseData: LogEntry, responseMessage: string, isCreated: boolean) => {
    if (isLogEntryCreate) {
      setSelectedLogEntry(responseData);
      setIsLogEntryCreate(false)
    } else {
      setSelectedLogEntry(undefined);
    }
    // TODO - once eq module gets refactored on the new .net api, change snackbar to display common message from upsertLogentry mutation "responseMessage"
    setSnackbar({
      open: true,
      message: responseMessage,
      type: 'success',
    });
  };
  // END

  // Is this the correct way to handle calling modals from the form?
  const handleCreateWorkIssue = async () => {
    const db = await getDatabase();
    const department = await db.tblddlistdefaults
      .findOne({
        selector: {
          fldListName: 'Department',
          fldMember: initialValue?.Department,
        },
      })
      .exec();
    const newIssue: WorkIssuesDocument = await db.workissues.newDocument({
      JobNumber: uuid(),
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      // Department: department?.PKey,
      Department: initialValue?.Department,
      EqKey: initialValue?.EqKey,
    });
    setSelectedWorkIssue(newIssue);
    setWorkIssueDialogVisible(true);
  };

  const handleSaveWorkIssue = async () => {
    setSelectedWorkIssue(undefined);
    setWorkIssueDialogVisible(false);
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.primary || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      const res = await db.collections.equipment.upsert(payload);
      onSave(res, false, 'tasks');
      getDocumentCount();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
    setSnackbar({
      open: true,
      message: 'New task has been created!',
      type: 'success',
    });
  };

  const { EQUIPMENT } = CHAR_LIMIT;
  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getDocumentCount = async () => {
    if (isNil(initialValue.EqKey)) {
      return;
    }
    const db = await getDatabase();
    activeSubscriptions.current = [
      db.workissues
        .find({
          selector: {
            EqKey: initialValue.EqKey,
            Completed: { $eq: false },
            deletedAt: { $eq: null },
          },
        })
        .$.subscribe((c: any) => {
          setOutStandingTasksCount(size(c));
        }),

      db.inveq
        .find({
          selector: {
            EqKey: initialValue.EqKey,
            deletedAt: { $eq: null },
          },
        })
        .$.subscribe(async (inventoryItems: any) => {
          const results = await Promise.all<RxDocument<InventoryDocType, InventoryDocMethods> | null>(
            inventoryItems.map(async (invItem: any) => {
              const revision = await db.inventory
                .findOne({
                  selector: {
                    ProductID: invItem.ProductID,
                    deletedAt: { $eq: null },
                  },
                })
                .exec();
              return revision;
            }),
            );
          // Filter out null values from the results array
          const validResults = results.filter(result => result !== null);
          setSpareCount(size(validResults));
        }),
    ];
  };

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
      fldLocHierarchy: initialValue.fldLocHierarchy || null,
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  const onTaskComplete = async () =>{
    const db = await getDatabase()
    const eq = await db.equipment.findOne({
      selector:{
        EqKey: initialValue.EqKey
      }
    }).exec()
    setValue('Hours',eq?.Hours)
  }

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;

    if (name === 'fldSRHKey') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldSRHKey === updatedValue) {
        shouldDirty = false;
      }
    }

    if (name === 'fldLocHierarchy') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldLocHierarchy === updatedValue) {
        shouldDirty = false;
      }
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    setoldEqUndo([]);
    onCancel();
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const db = await getDatabase();
    const {
      UniqueName,
      Manufacturer,
      ModelNumber,
      Hours,
      fldCountHours,
      fldParent,
      fldSRHKey,
      fldLocHierarchy,
      Department,
      fldReportingType,
      fldRestricted,
      fldSMS,
      Notes,
      Supplier,
      fldInService,
      fldExpireDate,
      SerialNum,
      ArrangementNum,
      Rating,
      ArtNumber,
      DateEntered,
    } = data;

    const document = {
      ...eqitem,
      UniqueName,
      Manufacturer: typeof Manufacturer === 'object' ? Manufacturer?.DisplayMember : Manufacturer || null,
      ModelNumber,
      Hours: parseInt(Hours, 10) || null,
      fldCountHours,
      fldParent: fldParent || null,
      DateEntered,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      fldLocHierarchy: (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy) || null,
      Department: isString(Department) ? Department : Department?.fldMember || null,
      fldReportingType: isString(fldReportingType) ? fldReportingType : fldReportingType?.fldMember || null,
      fldRestricted,
      fldSMS,
      Notes,
      Supplier: Supplier?.DisplayMember || Supplier || null,
      fldInService: !isNaN(fldInService) ? normalizeDateTime(fldInService) : null,
      fldExpireDate: !isNaN(fldExpireDate) ? normalizeDateTime(fldExpireDate) : null,
      SerialNum,
      ArrangementNum,
      Rating,
      ArtNumber,
      SearchMaker: isNil(Manufacturer)? null: typeof Manufacturer === 'object' ? removeNonAlphanumericChars(Manufacturer?.DisplayMember) : removeNonAlphanumericChars(Manufacturer) || null,
      SearchMod: isNil(ModelNumber)? null: removeNonAlphanumericChars(ModelNumber),
      updatedAt: new Date().toISOString(),
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (EqKey)
      EqKey: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
    } as any;

    try {
      setoldEqUndo([]);
      setEqItem(document)
      const res = await db.collections.equipment.upsert(document);

      onSave(res, isCreate);

      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }

  const handleHours = () => {
    setHoursDialog(true);
  }

  const handleUndo = () => {
    const item = last(oldEqUndo);
    setEqItem(item as Equipment);
    // Remove last step from our store
    setoldEqUndo(oldEqUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleCancelUndo = () => {
    if (isCreate) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleCancelDialog = () => {
    setSelectedWorkIssue(undefined);
    setWorkIssueDialogVisible(false);
    setSelectedLogEntry(undefined);
    setHoursDialog(false);
    handleClose();
  };

  const onOutStandingTaskDelete = async (key: string) => {
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.primary || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      const res = await db.collections.equipment.upsert(payload);
      onSave(res, false, 'tasks');
      getDocumentCount();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const onOutStandingTaskCreate = async () => {
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.primary || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      const res = await db.collections.equipment.upsert(payload);
      onSave(res, false, 'tasks');
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  if (isNil(eqitem)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;
    
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);
  
  const actions = [
    {
      icon: (
        <Icon>
          <img src={TaskIconAdd} alt="" />
        </Icon>
      ),
      name: 'Add task',
      onclick: handleCreateWorkIssue,
    },
    {
      icon: (
        <Icon>
          <img src={LogIconAdd} alt="" />
        </Icon>
      ),
      name: 'Add Log Entry',
      onclick: handleCreateLogEntry,
    },
    // hiding 'Equipment Hours' icon based on TD-1480
    // {
    //   icon: (
    //     <Icon>
    //       <img src={ClockIconAdd} alt="" />
    //     </Icon>
    //   ),
    //   name: 'Equipment Hours',
    //   onclick: handleHours,
    // },
  ];

  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <>
      <form
        id="EquipmentDetailForm"
        className={`${formClass}`}
        onSubmit={handleSubmit(handleSave)}
      >
        <div className="bg-white h-full flex-grow pt-6">
          <div className="px-6 h-full flex flex-col">
            <div className="mb-6">
              {(moduleReadOnly || recordReadOnly) && (
                <RecordEditWarningCard />
              )}
              <div className="mui-textfield-header mb-2">
                <Input
                  inputProps={{
                    size: 'medium',
                    label: 'Equipment Name',
                    variant: 'standard',
                  }}
                  rules={{ required: true, maxLength: EQUIPMENT.UniqueName }}
                  warning={(value) => handleCharLimitWarning(value, EQUIPMENT.UniqueName)}
                  control={control}
                  name="UniqueName"
                />
              </div>

              <div className="mt-3">
                <CompaniesDropdownOld
                  control={control}
                  label="Manufacturer"
                  name="Manufacturer"
                  onChange={onChange}
                  variant="standard"
                  size="small"
                />
              </div>

              <div className="mt-3">
                <Input
                  inputProps={{
                    size: 'small',
                    label: 'Model Number',
                    variant: 'standard',
                  }}
                  rules={{ maxLength: EQUIPMENT.ModelNumber }}
                  warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ModelNumber)}
                  defaultValue={initialValue.ModelNumber}
                  control={control}
                  name="ModelNumber"
                />
              </div>
            </div>

            <div className="mt-6 mb-20">
              <Tabs
                tabs={[
                  {
                    label: 'Summary',
                    component: (
                      <EqSummaryForm
                        watch={watch}
                        control={control}
                        initialValue={initialValue}
                        form={eqitem}
                        onChange={onChange}
                        getValues={getValues}
                        setValue={setValue}
                        disableEdit = {moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                  {
                    label: `Tasks (${outStandingTasksCount})`,
                    component: (
                      <EqOutStandingTasks
                        initialValue={initialValue}
                        onDelete={onOutStandingTaskDelete}
                        onSave={onOutStandingTaskCreate}
                        count={outStandingTasksCount}
                        onTaskComplete={onTaskComplete}
                        disableEdit = {moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                  {
                    label: `Spares (${spareCount})`,
                    component: (
                      <EqSpares 
                        initialValue={initialValue}
                        disableEdit={recordReadOnly}
                        editFlag={moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                  {
                    // label: `History (${historyCount})`, // TODO - temporary hide the count until refactoring Equipment mudule with the .net api
                    label: `History`,
                    component: (
                      <HistoryTab 
                        keyName={'equipmentId'}
                        keyValue={initialValue.EqKey} 
                        setHistoryCount={setHistoryCount}
                        countHours={initialValue.fldCountHours as boolean}
                        recordReadOnly={moduleReadOnly || recordReadOnly} 
                      />
                    ),
                  },
                  {
                    label: (
                      <ScheduleTabButton 
                        selector={{ EqKey: initialValue.EqKey , deletedAt:{$eq:null} }} 
                      />
                    ),
                    component: (
                      <Schedules 
                        EqKey={initialValue.EqKey} 
                        selector={{ EqKey: initialValue.EqKey, deletedAt:{$eq:null} }} 
                        recordReadOnly={moduleReadOnly || recordReadOnly} 
                      />
                    )
                  },
                  {
                    // label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                    label: `Attachments`,
                    disabled: isCreate,
                    component: (
                      <AttachmentTab
                        recordId={initialValue.EqKey}
                        recordType={RecordType.Equipment}
                        recordTypeName={'Equipment'} // when refactor to .net api, add proper object.__typename for counts cache update
                        attachmentType={AttachmentType.Document}
                        categoryId={initialValue.fldSRHKey}
                        setAttachmentsCount={setDocumentsCount}
                        readOnly={moduleReadOnly || recordReadOnly} 
                      />
                    ),
                  },
                  {
                    // label: `Photos (${photosCount})`,
                    label: `Photos`,
                    disabled: isCreate,
                    component: (
                      <AttachmentTab
                        recordId={initialValue.EqKey}
                        recordType={RecordType.Equipment}
                        recordTypeName={'Equipment'} // when refactor to .net api, add proper object.__typename for counts cache update
                        attachmentType={AttachmentType.Photo}
                        categoryId={initialValue.fldSRHKey}
                        setAttachmentsCount={setPhotosCount}
                        readOnly={moduleReadOnly || recordReadOnly} 
                      />
                    ),
                  },
                  {
                    // label: `Comments (${commentsCount})`,
                    label: `Comments`,
                    disabled: isCreate,
                    component: (
                      <Comments
                        recordId={initialValue.EqKey}
                        recordType={RecordType.Equipment}
                        recordTypeName={'Equipment'} // when refactor to .net api, add proper object.__typename for counts cache update
                        setCommentsCount={setCommentsCount}
                        readOnly={moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>

        {type !== 'Dialog' && (
          <StickyAppBar
            cancelText="Cancel"
            okType={isEditing ? 'submit' : 'button'}
            okText={isEditing ? 'Save' : 'Close'}
            onOk={() => handleOk(isEditing)}
            disabled={(moduleReadOnly || recordReadOnly) && isEditing}
            onCancel={isEditing ? () => handleCancelUndo() : undefined}
          >
            {!isCreate && !recordReadOnly && !moduleReadOnly && !isNil(eqitem.EqKey) && (
              <Box sx={{ position: 'relative', height: 70 }}>
                <Backdrop open={open} />
                <SpeedDial
                  ariaLabel="SpeedDial tooltip example"
                  sx={{ position: 'absolute', bottom: 12, right: 12 }}
                  FabProps={{ size: 'small' }}
                  icon={(
                    <SpeedDialIcon
                      sx={{ fontSize: 'small' }}
                      icon={(<SpeedDialIcon />)}
                      openIcon={<CloseIcon />}
                    />
                  )}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      classes={classes}
                      onClick={action.onclick}
                    />
                  ))}
                </SpeedDial>
              </Box>
            )}
            {!moduleReadOnly && !isEmpty(oldEqUndo) && (
              <IconButton
                onClick={handleUndo}
                color="inherit"
                aria-label="Undo last step"
              >
                <Undo />
              </IconButton>
            )}
            {!isCreate && !(moduleReadOnly || recordReadOnly) && !isNil(eqitem.EqKey) && (
              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete item"
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </StickyAppBar>
        )}

        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content={equipmentDeleteWarningMessage}
          okText='Yes'
          color='error'
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />

        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </form>

      {hoursDialog && (
        <EqHoursUtility
          visible={hoursDialog}
          defaultEquipment={initialValue}
          onCancel={handleCancelDialog}
        />
      )}

      {!!selectedWorkIssue && (
        <EqNewTaskDialog
          visible={workIssueDialogVisible}
          initialValue={selectedWorkIssue}
          onSave={handleSaveWorkIssue}
          onCancel={handleCancelDialog}
          moduleReadOnly={moduleReadOnly}
          title="Create New Task"
        />
      )}

      <LogEntryFormDialog 
        selectedItem={selectedLogEntry} 
        isCreate={isLogEntryCreate}
        onSave={handleSaveLogEntry} 
        onCancel={handleCancelDialog} 
        moduleReadOnly={moduleReadOnly}
        refetchQueryVariables={null}
        callerComponent='EqDetailForm'     
      />
    </>
  );
};

export default EqDetailForm;
