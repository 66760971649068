import { gql, useQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";

export const GET_DASHBOARD_TILE_ENABLED = gql`
  query GetDashboardTileEnabled {
    appSettings {
      dashboardTileEnabled
    }
  }
`

interface GetDashboardTileEnabledResult {
  dashboardTileEnabled: boolean;
  dashboardTileEnabledLoading: boolean;
}

export const useGetDashboardTileEnabled = (): GetDashboardTileEnabledResult => {
  const { data, loading, error } = useQuery(GET_DASHBOARD_TILE_ENABLED, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });

  if (error) {
    logger('GetDashboardTileEnabled').error('Error fetching dashboard tile enable value -->', error.message);
  }
  
  return {
    dashboardTileEnabled: data?.appSettings?.dashboardTileEnabled,
    dashboardTileEnabledLoading: loading
  };
};

  