import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client';
import { Inventory } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LAZY_INVENTORY_BY_BARCODE = gql`
  query GetInventoryByBarcode($barcode: String!) {
    inventoryByBarcode(barcode: $barcode) {
      id
      productName
      manufacturer
      partNumber
      modelNumber
      department
      categoryId
      consumable
      inventoryType
      bonded
      sms
      country
      region
      color
      size
      reOrderLevel
      orderQty
      totalAmount
      documentsCount
      photosCount
      commentsCount
      equipmentCount
      ordersCount
    }
  }
`;

export interface InventoryByBarcodeData {
  inventoryByBarcode: Inventory[];
};

interface InventoryByBarcodeResult {
  getLazyInventoryByBarcode: LazyQueryExecFunction<InventoryByBarcodeData, OperationVariables>;
}

export const useGetLazyInventoryByBarcode = (): InventoryByBarcodeResult => {
  const [ getLazyInventoryByBarcode, { error }] = useLazyQuery(GET_LAZY_INVENTORY_BY_BARCODE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyInventoryById').error('Error fetching inventory -->', error.message);
  }

  return {
    getLazyInventoryByBarcode
  };
}
